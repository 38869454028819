import { AUTHENTICATED, USER } from '../types/auth';

//state
const initialState = {
    autenticated: false,
    user: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                autenticated: action.payload,
            }

        case USER:
            return {
                ...state,
                user: action.payload,
            }

        default:
            return state;
    }
}