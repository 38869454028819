import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import Pdf from '../../instrument/pdf/Pdf';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import DocuPDF from './DocuPDF';
import { Radar } from 'react-chartjs-2';
import './pdfsModal.css'

import "chartjs-plugin-datalabels";
// import Issues from "../components/Issues";

import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);


const options = {
    tooltips: false,
    maintainAspectRatio: false,
    scales: {
        r: {
            angleLines: {
                display: true
            },
            pointLabels: {
                font: {
                    size: 14,
                    color: 'gray'
                }
            },
            ticks: {
                display: false
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    },
    plugins: {
        datalabels: {
            align:'left',
            offset: 1,
            formatter: function (value, context) {
                return value.toFixed(2);
            }
        }
    }
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-30%',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#root');

export const PdfsModal = ({ dataChart, formvalues, pdfModalopen, setPdfModalopen, axes, company }) => {
    const [imagesCharts, setImagesCharts] = useState([])
    const hideContent = useRef(false)

    function closeModal() {
        setPdfModalopen(false);
    }

    const generar = () => {
        let tags = document.getElementsByClassName('grafica');
        let arrayImages = []
        dataChart.map((item, index) => {
            arrayImages.push({image:tags[index].toDataURL('image/png'), media:item.media});
        })
        setImagesCharts(arrayImages);
        hideContent.current = true
    }

    return (
        <div className="PdfsModal">
            <Modal
                isOpen={pdfModalopen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="userModalBox">
                    <div className="userModalHeader">
                        <div>
                            <strong id="iii">Descargar Pdf</strong>
                        </div>
                        <div style={{ cursor: 'pointer', float: 'right' }} onClick={closeModal}><strong>X</strong></div>
                    </div>
                    <div style={{width:'800px'}}>
                        {/* {imagesCharts.length > 0 &&
                            <PDFDownloadLink
                                document={<DocuPDF imagesCharts={imagesCharts} formvalues={formvalues} company={company} axes={axes} />
                                }
                                fileName="reporte.pdf"
                            >
                                Descargar PDF
                            </PDFDownloadLink>
                        } */}
                        {!hideContent.current &&
                            <div style={{ cursor: 'pointer' }} onClick={generar}>Descargar</div>
                        }
                        {/* <Pdf dataChart={dataChart} formvalues={formvalues} /> */}
                        <div className="contenido" style={{ overflowY: 'auto', overflowWrap: 'break-word', height: '600px' }}>
                            {imagesCharts.length > 0 &&
                                <PDFViewer style={{ width: "100%", height: "80vh" }}>
                                    <DocuPDF imagesCharts={imagesCharts} formvalues={formvalues} company={company} axes={axes} />
                                </PDFViewer>
                            }
                            {!hideContent.current &&
                                <div>
                                    {dataChart.map((item, index) => {
                                        return (
                                            <div style={{ paddingBottom: '100px' }}>
                                                <hr />
                                                <div style={{ display: 'inline-flex', display: 'flex', justifyContent: 'space-between' }}>
                                                    <img style={{ width: "120px", height: "50px" }} src={require('../../../images/ui/valle.png').default} alt="" />
                                                    {/* <img style={{ width: "120px", height: "40px" }} src={require('../../../images/ui/impulsa.png').default} alt="" /> */}
                                                </div>
                                                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                                                    <strong>{axes.length > 0 && axes[index].name}</strong>
                                                </div>
                                                <div>Media:{item.media.toFixed(2)}</div>
                                                <div style={{ width: '800px', height:'400px' }}>
                                                    <Radar className="grafica" data={item.datos} options={options} />
                                                </div>
                                                <div style={{ width: '500px' }}>
                                                    {formvalues.length > 0 && formvalues[index].analysis}
                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Modal >
        </div>

    )
}
