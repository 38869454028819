import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Radar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf"

const options = {

    scales: {
        r: {
            angleLines: {
                display: true
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    }
};

class Pdf extends Component {

    constructor(props) {
        super(props)
        this.state = { datachart: props.dataChart, text: props.formvalues };
    }


    div2PDF = e => {
        /////////////////////////////
        // Hide/show button if you need
        /////////////////////////////

        const but = e.target;
        but.style.display = "none";
        let input = window.document.getElementsByClassName("div2PDF")[0];

        html2canvas(input).then(canvas => {
            const img = canvas.toDataURL("image/png");
            var imgWidth = 500;
            var pageHeight = 900;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            //const pdf = new pdfConverter("l", "pt");
            //  const pdf = new jsPDF("1", "pt");
            var pdf = new jsPDF('p', 'pt', 'a4');
            var position = 0;

            pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('file.pdf');
            but.style.display = "block";
        });
    };

    render() {
        return (
            <div style={{ height:'50vh'}}>
                <div>
                    <button onClick={(e) => this.div2PDF(e)}>Export 2 PDF</button>
                </div>
                <div style={{width:'70%'}} className="div2PDF">
                    {this.state.datachart !== undefined &&
                        this.state.datachart.map((item, index) => {
                            return (
                                <div style={{ height: '2000px', width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <img style={{ width: '24%', height: '70px', marginTop: '20px' }} src={require('../../../images/ui/mincomercio.png').default} alt="" />
                                        <img style={{ width: '18%', height: '70px', marginTop: '20px' }} src={require('../../../images/ui/impulsa.png').default} alt="" />
                                    </div>

                                    <div style={{ width: '70%', height: '800px', marginTop: '300px', marginLeft: '250px' }}>
                                        <Radar data={item} options={options} />
                                        <p>{this.state.text[index].analysis}</p>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Pdf;

ReactDOM.render(<Pdf />, document.getElementById("root"));