import React, { useState } from 'react';
import clienteAxios from '../../config/axios';
import './satisfactionSurvey.css'

const SatisfactionSurvey = () => {

    const [formValues, setformValues] = useState('');
    const [error, setError] = useState('');


    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }
    const validateData = async (event) => {
        event.preventDefault();

        let internalError = '';
        if (formValues.name === '' || formValues.name === undefined) {
            internalError = 'El nombre es requerido, ';
        }
        if (formValues.last_name === '' || formValues.last_name === undefined) {
            internalError = 'El apellido es requerido, ';
        }
        if (formValues.doc_identity === '' || formValues.doc_identity === undefined) {
            internalError = 'El documento de identidad es requerido, ';
        }
        if (formValues.calidad_contenido === '' || formValues.calidad_contenido === undefined) {
            internalError = 'La calidad del contenido es requerida, ';
        }
        if (formValues.aplicacion_empresa === '' || formValues.aplicacion_empresa === undefined) {
            internalError = '¿El contenido presentado en las sesiones grupales ha tenido aplicación para tu empresa? es requerida, ';
        }
        if (formValues.victor_tamayo_pertinencia === '' || formValues.victor_tamayo_pertinencia === undefined) {
            internalError = 'La calificación para Victor Tamayo es requerida, ';
        }
        if (formValues.julian_taborda_pertinencia === '' || formValues.julian_taborda_pertinencia === undefined) {
            internalError = 'La calificación para Julian Taborda es requerida, ';
        }
        if (formValues.luis_carolina_pertinencia === '' || formValues.luis_carolina_pertinencia === undefined) {
            internalError = 'La calificación para Luis Bahamón y Carolina es requerida, ';
        }
        if (formValues.daniel_gomez_pertinencia === '' || formValues.daniel_gomez_pertinencia === undefined) {
            internalError = 'La calificación para  Daniel Gómez es requerida, ';
        }
        if (formValues.oscar_quintero_pertinencia === '' || formValues.oscar_quintero_pertinencia === undefined) {
            internalError = 'La calificación para  Oscar Quintero es requerida, ';
        }
        if (formValues.jorge_lopez_pertinencia === '' || formValues.jorge_lopez_pertinencia === undefined) {
            internalError = 'La calificación para  Jorge López es requerida, ';
        }
        if (formValues.franco_santos_pertinencia === '' || formValues.franco_santos_pertinencia === undefined) {
            internalError = 'La calificación para  Franco Santos es requerida, ';
        }
        if (formValues.victor_tamayo_metodologia === '' || formValues.victor_tamayo_metodologia === undefined) {
            internalError = 'La calificación para Victor Tamayo es requerida, ';
        }
        if (formValues.julian_taborda_metodologia === '' || formValues.julian_taborda_metodologia === undefined) {
            internalError = 'La calificación para Julian Taborda es requerida, ';
        }
        if (formValues.luis_carolina_metodologia === '' || formValues.luis_carolina_metodologia === undefined) {
            internalError = 'La calificación para Luis Bahamón y Carolina es requerida, ';
        }
        if (formValues.daniel_gomez_metodologia === '' || formValues.daniel_gomez_metodologia === undefined) {
            internalError = 'La calificación para  Daniel Gómez es requerida, ';
        }
        if (formValues.oscar_quintero_metodologia === '' || formValues.oscar_quintero_metodologia === undefined) {
            internalError = 'La calificación para  Oscar Quintero es requerida, ';
        }
        if (formValues.franco_santos_metodologia === '' || formValues.franco_santos_metodologia === undefined) {
            internalError = 'La calificación para  Franco Santos es requerida, ';
        }
        if (formValues.jorge_lopez_metodologia === '' || formValues.jorge_lopez_metodologia === undefined) {
            internalError = 'La calificación para  Jorge López es requerida, ';
        }
        if (formValues.material_entregado === '' || formValues.material_entregado === undefined) {
            internalError = '¿Cómo te ha parecido la calidad del material entregado en las sesiones en vivo? es requerida, ';
        }
        if (formValues.masterclass === '' || formValues.masterclass === undefined) {
            internalError = '¿Cómo te ha parecido las masterclass? es requerida, ';
        }
        if (formValues.satisfaccion === '' || formValues.satisfaccion === undefined) {
            internalError = '¿En términos generales, cuál es tu satisfacción hasta el momento con las sesiones grupales? es requerida, ';
        }
        if (formValues.mejorar === '' || formValues.mejorar === undefined) {
            internalError = '¿Qué elementos consideras que debemos mejorar? es requerida, ';
        }
        if (internalError === '') {
            setError('');

            formValues.victor_tamayo = 'pertinencia: ' + formValues.victor_tamayo_pertinencia + ', ' + 'metodologia: ' + formValues.victor_tamayo_metodologia
            formValues.julian_taborda = 'pertinencia: ' + formValues.julian_taborda_pertinencia + ', ' + 'metodologia: ' + formValues.julian_taborda_metodologia
            formValues.luis_carolina = 'pertinencia: ' + formValues.luis_carolina_pertinencia + ', ' + 'metodologia: ' + formValues.luis_carolina_metodologia
            formValues.daniel_gomez = 'pertinencia: ' + formValues.daniel_gomez_pertinencia + ', ' + 'metodologia: ' + formValues.daniel_gomez_metodologia
            formValues.oscar_quintero = 'pertinencia: ' + formValues.oscar_quintero_pertinencia + ', ' + 'metodologia: ' + formValues.oscar_quintero_metodologia
            formValues.franco_santos = 'pertinencia: ' + formValues.franco_santos_pertinencia + ', ' + 'metodologia: ' + formValues.franco_santos_metodologia
            formValues.jorge_lopez = 'pertinencia: ' + formValues.jorge_lopez_pertinencia + ', ' + 'metodologia: ' + formValues.jorge_lopez_metodologia


            let response = await clienteAxios.post(`SatisfactionSurveysController`, formValues);
            if (!response.data.hasOwnProperty('error')) {
                alert('Respuestas enviadas')
            }

        } else {
            setError(internalError);
        }
    }

    return (
        <div className="satisfactionsurveycomponentBackground">
            <div className="satisfactionsurveycomponent">
                <br />
                <br />
                <h4>Encuesta de satisfacción</h4>
                <div className="groupFieldSurvey">
                    Nos encantaría conocer tu percepción sobre las sesiones grupales, así que te pedimos que nos regales un tiempo para que nos compartes que piensas y podamos seguir mejorando.
                </div>
                <form>
                    <div className="groupFieldSurvey">
                        <div className="field">
                            <label>Nombre</label>
                        </div>
                        <div className="field">
                            <input className="inputField" type="text" name="name" value={formValues.name} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="groupFieldSurvey">
                        <div className="field">
                            <label>Apellido</label>
                        </div>
                        <div className="field">
                            <input className="inputField" type="text" name="last_name" value={formValues.last_name} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="groupFieldSurvey">
                        <div className="field">
                            <label>Identificación</label>
                        </div>
                        <div className="field">
                            <input className="inputField" type="text" name="doc_identity" value={formValues.doc_identity} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="groupFieldSurvey">
                        <div className="field">
                            <label>¿Cómo te ha parecido la calidad del contenido?</label>
                        </div>
                        <div className="field">
                            <select className="inputField" name="calidad_contenido" value={formValues.calidad_contenido} onChange={handleInputChange}>
                                <option value="">Seleccione calificación</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>
                    <div className="groupFieldSurvey">
                        <div className="field">
                            <label>¿El contenido presentado en las sesiones grupales ha tenido aplicación para tu empresa?</label>
                        </div>
                        <div className="field">
                            <select className="inputField" name="aplicacion_empresa" value={formValues.aplicacion_empresa} onChange={handleInputChange}>
                                <option value="">-----</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ border: '1px solid gray', marginRight: '100px', marginLeft: '100px' }}>
                        <div className="groupFieldSurvey">
                            <label>Evalua los expertos</label>
                            <br />
                            <label>Victor Tamayo</label>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿Qué tan pertinente fue el contenido dado por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="victor_tamayo_pertinencia" value={formValues.victor_tamayo} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>

                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿cómo te pareció la metodología utilizada por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="victor_tamayo_metodologia" value={formValues.victor_tamayo} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>

                        </div>
                        <div className="groupFieldSurvey">
                            <label>Julián Taborda</label>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿Qué tan pertinente fue el contenido dado por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="julian_taborda_pertinencia" value={formValues.julian_taborda} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿cómo te pareció la metodología utilizada por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="julian_taborda_metodologia" value={formValues.julian_taborda} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <label>Luis Bahamón y Carolina</label>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿Qué tan pertinente fue el contenido dado por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="luis_carolina_pertinencia" value={formValues.luis_carolina} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿cómo te pareció la metodología utilizada por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="luis_carolina_metodologia" value={formValues.luis_carolina} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <label>Daniel Gómez</label>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿Qué tan pertinente fue el contenido dado por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="daniel_gomez_pertinencia" value={formValues.daniel_gomez} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿cómo te pareció la metodología utilizada por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="daniel_gomez_metodologia" value={formValues.daniel_gomez} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <label>Oscar Quintero</label>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿Qué tan pertinente fue el contenido dado por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="oscar_quintero_pertinencia" value={formValues.oscar_quintero} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿cómo te pareció la metodología utilizada por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="oscar_quintero_metodologia" value={formValues.oscar_quintero} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <label>Franco Santos</label>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿Qué tan pertinente fue el contenido dado por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="franco_santos_pertinencia" value={formValues.oscar_quintero} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿cómo te pareció la metodología utilizada por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="franco_santos_metodologia" value={formValues.oscar_quintero} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <label>Jorge López</label>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿Qué tan pertinente fue el contenido dado por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="jorge_lopez_pertinencia" value={formValues.oscar_quintero} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="groupFieldSurvey">
                            <div className="field">
                                <label>¿cómo te pareció la metodología utilizada por el experto?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="jorge_lopez_metodologia" value={formValues.oscar_quintero} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <div className="groupFieldSurvey">
                        <div className="field">
                            <label>¿Cómo te ha parecido la calidad del material(presentaciones, documentos de apoyo) entregado en las sesiones en vivo?</label>
                        </div>
                        <div className="field">
                            <select className="inputField" name="material_entregado" value={formValues.material_entregado} onChange={handleInputChange}>
                                <option value="">Seleccione calificación</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>
                    <div className="groupFieldSurvey">
                        <div className="field">
                            <label>¿Cómo te ha parecido las masterclass?</label>
                        </div>
                        <div className="field">
                            <select className="inputField" name="masterclass" value={formValues.masterclass} onChange={handleInputChange}>
                                <option value="">Seleccione calificación</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>
                    <div className="groupFieldSurvey">
                        <div className="field">
                            <label>¿En términos generales, cuál es tu satisfacción hasta el momento con las sesiones grupales?</label>
                        </div>
                        <div className="field">
                            <select className="inputField" name="satisfaccion" value={formValues.satisfaccion} onChange={handleInputChange}>
                                <option value="">Seleccione calificación</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>
                    <div className="groupFieldSurvey">
                        <div className="field">
                            <label>¿Qué elementos consideras que debemos mejorar?</label>
                        </div>
                        <div className="field">
                            <textarea value={formValues.mejorar} className="inputField" onChange={handleInputChange} name="mejorar" rows="10" cols="50"></textarea>
                        </div>
                    </div>
                    {error !== '' && <div className="groupFieldSurvey" style={{ color: 'red', padding: '100px' }}>{error}</div>}
                    <br />
                    <br />
                    <button onClick={validateData} className="btn btn-primary sendLogin">Enviar</button>
                    <br />
                    <br />
                </form>
            </div>
        </div>
    )
}

export default SatisfactionSurvey
