import React, { useState } from 'react';
import clienteAxios from '../../config/axios';
import './satisfactionProspective.css'

const SatisfactionSurveyProspective = () => {

    const [sent, setSent] = useState(false)
    const [formValues, setformValues] = useState('');
    const [error, setError] = useState('');


    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const validateData = async (event) => {
        event.preventDefault();

        let internalError = '';
        if (formValues.name === '' || formValues.name === undefined) {
            internalError = 'El nombre es requerido, ';
        }
        if (formValues.lastname === '' || formValues.lastname === undefined) {
            internalError = 'El apellido es requerido, ';
        }
        if (formValues.docIdentity === '' || formValues.docIdentity === undefined) {
            internalError = 'El documento de identidad es requerido, ';
        }
        if (formValues.nivel_organizacion === '' || formValues.nivel_organizacion === undefined) {
            internalError = 'El Nivel en la organización es requerido, ';
        }
        if (formValues.recomendacion === '' || formValues.recomendacion === undefined) {
            internalError = '¿Qué tan probable es que recomiende a VT SAS para un ejercicio de Prospectiva Estratégica? es requerido, ';
        }
        if (formValues.satisfaccion_resultados === '' || formValues.satisfaccion_resultados === undefined) {
            internalError = '¿Que tan satisfecho está con los resultados de Prospectiva Estratégica? es requerido, ';
        }
        if (formValues.satisfaccion_proceso === '' || formValues.satisfaccion_proceso === undefined) {
            internalError = '¿Qué tan satisfecho está con el proceso de acompañamiento, se ha sentido respaldado y acompañado? es requerido, ';
        }
        if (formValues.pertinente === '' || formValues.pertinente === undefined) {
            internalError = '¿Qué tan pertinente considera el equipo de consultores que acompañaron el proceso, desde el punto de vista técnico y humano? es requerida, ';
        }
        if (formValues.relevante === '' || formValues.relevante === undefined) {
            internalError = '¿Qué tan relevantes considera las herramientas digitales usadas durante el proceso? es requerido, ';
        }
        if (formValues.aplicado_resultados === '' || formValues.aplicado_resultados === undefined) {
            internalError = '¿De qué manera ha aplicado los resultados? es requerido, ';
        }

        if (internalError === '') {
            setError('');
            let response = await clienteAxios.post(`prospectivaSurvey`, formValues);
            if (!response.data.hasOwnProperty('error')) {
                setSent(true);
            }
        } else {
            setError(internalError);
        }

    }


    return (
        <div className="SatisfactionSurveyProspectivemain">
            <div className="satisfactionsurveycomponent">
                <br />
                <br />
                <h4>Encuesta de satisfacción Prospectiva</h4>
                {!sent ?
                    <div>
                        <form >
                            <div className="groupFieldSurvey">
                                <div className="field">
                                    <label>Nombre</label>
                                </div>
                                <div className="field">
                                    <input className="inputField" type="text" name="name" value={formValues.name} onChange={handleInputChange} />
                                </div>

                                <div className="field">
                                    <label>Apellido</label>
                                </div>
                                <div className="field">
                                    <input className="inputField" type="text" name="lastname" value={formValues.lastname} onChange={handleInputChange} />
                                </div>

                                <div className="field">
                                    <label>Identificación</label>
                                </div>
                                <div className="field">
                                    <input className="inputField" type="text" name="docIdentity" value={formValues.docIdentity} onChange={handleInputChange} />
                                </div>
                                <div className="field">
                                    <label>Nivel en la organización</label>
                                </div>
                                <div className="field">
                                    <input className="inputField" type="text" name="nivel_organizacion" value={formValues.nivel_organizacion} onChange={handleInputChange} />
                                </div>
                                <div className="field">
                                    <label>¿Qué tan probable es que recomiende a VT SAS para un ejercicio de Prospectiva Estratégica?
                                    </label>
                                </div>
                                <div className="field">
                                    <select className="inputField" name="recomendacion" value={formValues.recomendacion} onChange={handleInputChange}>
                                        <option value="">Seleccione calificación</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>
                                <div className="field">
                                    <label>¿Que tan satisfecho está con los resultados de Prospectiva Estratégica?
                                    </label>
                                </div>
                                <div className="field">
                                    <select className="inputField" name="satisfaccion_resultados" value={formValues.satisfaccion_resultados} onChange={handleInputChange}>
                                        <option value="">Seleccione calificación</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                                <div className="field">
                                    <label>¿Qué tan satisfecho está con el proceso de acompañamiento, se ha sentido respaldado y acompañado?
                                    </label>
                                </div>
                                <div className="field">
                                    <select className="inputField" name="satisfaccion_proceso" value={formValues.satisfaccion_proceso} onChange={handleInputChange}>
                                        <option value="">Seleccione calificación</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                                <div className="field">
                                    <label>¿Qué tan pertinente considera el equipo de consultores que acompañaron el proceso, desde el punto de vista técnico y humano?
                                    </label>
                                </div>
                                <div className="field">
                                    <select className="inputField" name="pertinente" value={formValues.pertinente} onChange={handleInputChange}>
                                        <option value="">Seleccione calificación</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                                <div className="field">
                                    <label>¿Qué tan relevantes considera las herramientas digitales usadas durante el proceso?
                                    </label>
                                </div>
                                <div className="field">
                                    <select className="inputField" name="relevante" value={formValues.relevante} onChange={handleInputChange}>
                                        <option value="">Seleccione calificación</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                                <div className="field">
                                    <label>¿De qué manera ha aplicado los resultados?
                                    </label>
                                </div>
                                <div className="field">
                                    <textarea style={{ border: '1px solid gray' }} rows="7" cols="50" type="text" name="aplicado_resultados" value={formValues.aplicado_resultados} onChange={handleInputChange} />
                                </div>
                            </div>
                            {error !== '' && <div className="groupFieldSurvey" style={{ color: 'red', padding: '100px' }}>{error}</div>}
                            <br />
                            <br />
                            <button onClick={validateData} className="btn btn-primary sendLogin">Enviar</button>
                            <br />
                            <br />
                        </form>
                    </div> :
                    <div className="sent">
                        Respuestas enviadas!
                    </div>
                }
            </div>
        </div>
    )
}

export default SatisfactionSurveyProspective
