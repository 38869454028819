import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import './questions.css'

const Questions = () => {

    const [edit, setEdit] = useState('');
    const questions = useSelector(state => state.instruments.instrument);

    const save = ()=>{
        setEdit('')
    }

    return (
        <div className="questionsComponent">
            <div className="bodyQuestionsTable">
            {questions !== undefined &&
                questions.map((item, index) => {
                    return (<div className="questions">
                        <div>{index + 1}¿{item.question}?</div>
                    </div>);
                })
            }

            </div>

        </div>
    )
}

export default Questions
