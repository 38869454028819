import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Radar } from 'react-chartjs-2';
import clienteAxios from '../../../config/axios';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { validateTokenAuth } from '../../../redux/actions/authAction'
import { getAllInstruments, viewRadars } from '../../../redux/actions/instrumentAction'
import { getScores } from '../../../redux/actions/scoreAction';
import './punctualRelationshipByCompany.css'
import { useHistory } from 'react-router-dom';
import Pdf from '../pdf/Pdf';
import { logOut } from '../../../redux/actions/authAction';
import DocuPDF from '../pdf/DocuPDF';
import Trazability from '../../../helpers/Trazability';
import { PdfsModal } from '../../modals/pdfs/PdfsModal';

import "chartjs-plugin-datalabels";
// import Issues from "../components/Issues";

import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

const options = {
    tooltips: false,
    maintainAspectRatio: false,
    scales: {
        r: {
            angleLines: {
                display: true
            },
            pointLabels: {
                font: {
                    size: 14,
                    color: 'gray'
                }
            },
            ticks: {
                display: false
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    },
    plugins: {
        datalabels: {
            align: 'left',
            offset: 1,
            formatter: function (value, context) {
                return value.toFixed(2);
            }
        }
    }
};

const PunctualRelationshipByCompany = ({ ...props }) => {
    // const instruments = useSelector(state => state.instruments.instrumentsList);
    const radarView = useSelector(state => state.instruments.radarView);

    const dispatch = useDispatch();
    const history = useHistory();

    //states
    const [dataChart, setDataChart] = useState([])
    const [axes, setAxes] = useState('')
    const user = useSelector(state => state.auth.user);
    console.log('user', user);
    const [formvalues, setformvalues] = useState([])
    const [companyId, setCompanyId] = useState('')
    const [companies, setCompanies] = useState('')
    const [companiesList, setcompaniesList] = useState('')
    const [consultor, setConsultor] = useState(false)
    const [auth, setAuth] = useState(false)
    const [instruments, setListaInstruments] = useState('')
    const [axesButtons, setAxesButtons] = useState('')
    //const [score, setScores] = useState('')
    const [companySelected, setCompanySelected] = useState('')
    const [instrumentSelected, setInstrumentSelected] = useState('')
    const [description, setdescription] = useState({ id: '', description: '' })


    //abrir y cerrar modal de pdfs
    const [pdfModalopen, setPdfModalopen] = useState(false)

    useEffect(() => {
        if (radarView) {
            getCompanies();
            getInstruments();
        } else {
            getAxes(props.match.params.instrument)
            validateToken(props.match.params.token);
        }
    }, [])

    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            setAuth(true)
            let exist = user.role.find(item => item.name === "consultor" || item.name === "admin");
            if (exist !== undefined) {
                setConsultor(true);
                getCompaniesConsultant();
            }
        }
    }, [user])


    const getCompanies = async () => {
        try {
            let response = await clienteAxios.get(`companies`);
            setcompaniesList(response.data);
        } catch (error) {

        }
    }

    const getInstruments = async (company) => {
        // dispatch(getAllInstruments());
        try {
            let response = await clienteAxios.get(`instrumentsByCompany/${company}`);
            setListaInstruments(response.data.companyInstrument);
        } catch (error) {

        }
    }

    const getCompaniesConsultant = async () => {
        try {
            let response = await clienteAxios.get(`companiesByConsultant/${user.user.id}`);
            setCompanies(response.data.companiesConsultant);
        } catch (error) {

        }
    }

    const validateToken = (token) => {
        try {
            dispatch(validateTokenAuth(token));
        } catch (error) {

        }
    }

    // useEffect(() => {
    //     if (Object.keys(score).length !== 0) {
    //         if (score.score.length > 0) {
    //             graphScore();
    //         }
    //     }
    // }, [score])

    const graphScore = (score) => {
        try {
            let components = []
            score.score.map(item => {
                components.push(item.component_id)
            })

            let uniq = [...new Set(components)];

            let arrayScores = [];
            uniq.forEach((item, index) => {
                let obj = {}
                let nameComponent = 'component' + item.toString()
                obj[nameComponent] = 0;
                obj.idComponent = item.toString();
                obj.accumulatedScores = 0;
                obj.numberOfScores = 0
                arrayScores.push(obj);
            });

            score.score.map(item => {
                arrayScores.map(i => {
                    if (item.component_id == i.idComponent) {
                        i[Object.keys(i)[2]] = i[Object.keys(i)[2]] + parseInt(item.media)
                        i.numberOfScores = i.numberOfScores + 1;
                    }
                })
            })

            arrayScores.map(item => {
                item.finalMedia = item.accumulatedScores / item.numberOfScores
                return item;
            })
            let labels = [];
            let data = []
            arrayScores.map((item) => {
                data.push(item.finalMedia)

                labels.push(item.idComponent)
            })

            labels = labels.map((item, index) => {
                let newData = score.componentsNames.find(i => i.id == parseInt(item)).name
                return newData;
            })


            let sum = data.reduce((previous, current) => current += previous);
            let avg = sum / data.length;


            let arrayColors = Array.from({ length: 10 }, (_, i) => 'rgba(98, 181, 249)')
            // arrayColors[data.indexOf(Math.max(...data))] = 'green';
            let menor1 = Math.min(...data)
            arrayColors[data.indexOf(menor1)] = 'red';

            let auxdata = [...data];
            // auxdata.splice(data.indexOf(Math.max(...data)), 1)
            auxdata.map((item, index) => {
                if (item == menor1) {
                    auxdata.splice(index, 1);
                }
            })

            // arrayColors[data.indexOf(Math.max(...auxdata))] = 'green';
            let menor2 = Math.min(...removeItemAll(auxdata, menor1))
            // arrayColors[data.indexOf(menor2)] = 'red';

            data.forEach((item, index) => {
                if (item >= 4) {
                    arrayColors[data.indexOf(item)] = 'green';
                }
                if (item == menor1) {
                    arrayColors[index] = 'red';
                }
                if (item == menor2) {
                    let exist = data.find(element => element > menor2)
                    if (exist != undefined) {
                        arrayColors[index] = 'red';
                    }
                }
            })

            let graph = {
                labels: labels,
                datasets: [
                    {
                        label: 'Valor del eje',
                        data: data,
                        backgroundColor: 'rgba(98, 181, 249, 0.3)',
                        borderColor: 'rgba(98, 181, 249, 251 )',
                        borderWidth: 0,
                        pointBackgroundColor: arrayColors

                    }

                    // {
                    //     label: 'test2',
                    //     data: Array.from({length: data.length}, () => 3),
                    //     backgroundColor: 'hsla(0, 0%, 0%, 0)',
                    //     borderColor: 'gray',
                    //     borderWidth: 1,
                    // },
                    // {
                    //     label: 'test',
                    //     data: Array.from({length: data.length}, () => 4),
                    //     backgroundColor: 'hsla(0, 0%, 0%, 0)',
                    //     borderColor: 'gray',
                    //     pointRadius: 0,
                    //     borderWidth: 10,
                    // }
                ],
            }
            return [graph, avg];
        } catch (error) {
        }

    }

    function removeItemAll(arr, value) {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    }

    const getAxes = async (instrumentId) => {
        try {
            let response = await clienteAxios.get(`axesByInstrument/${instrumentId}`);
            getScoresOfAllComponents(response.data, instrumentId)
            setAxes(response.data)
        } catch (error) {

        }

    }

    const getScoresOfAllComponents = async (axesList, instrumentId) => {
        try {
            let idCompany = ''
            if (!radarView) {
                idCompany = await clienteAxios.get(`getcompanyByName/${props.match.params.company}`);
            }
            setCompanyId(radarView ? companySelected : idCompany.data[0].id);
            let allAnalysis = await clienteAxios.get(`getAnalysis/${radarView ? companySelected : idCompany.data[0].id}/${radarView ? instrumentId : props.match.params.instrument}`);
            if (idCompany != '' || radarView) {
                if (axesList !== undefined && axesList.length > 0) {
                    let arrayToFormvalues = []

                    let dataGraficos = []
                    for (var i = 0; i < axesList.length; i++) {
                        let exist = allAnalysis.data.find(analysis => analysis.axes_id == axesList[i].id);
                        arrayToFormvalues.push(exist !== undefined ? exist : { analysis: '', company_id: radarView ? companySelected : idCompany.data[0].id, axes_id: axesList[i].id, instrument_id: parseInt(radarView ? instrumentId : props.match.params.instrument) })
                        let response = await clienteAxios.get(`scoreUsers/${axesList[i].id}/${radarView ? companySelected : idCompany.data[0].id}`);
                        if (response.data.userScores.length > 0) {
                            let objetos = graphScore({ score: response.data.userScores, componentsNames: response.data.componentsNames })

                            dataGraficos.push({ datos: objetos[0], media: objetos[1] })
                        }

                    }

                    let axesClone = [...axesList]
                    setAxesButtons(axesClone.slice(0, dataGraficos.length))
                    setDataChart(dataGraficos)

                    setformvalues(arrayToFormvalues.slice(0, dataGraficos.length))
                }
            }

        } catch (error) {
        }

    }

    const hadleInputChange = (event) => {

        let exist = formvalues.indexOf(formvalues.find(item => item.axes_id == event.target.name.slice(8, event.target.name.length)));
        var tmp = [...formvalues];
        tmp[exist].analysis = event.target.value
        setformvalues(tmp);

    }

    const hadleInputChangeDescription = (event) => {
        var tmp = { ...description };
        tmp.description = event.target.value
        setdescription(tmp);
    }

    const sendData = async (eje) => {
        try {
            let dataToSend = formvalues.find(data => data.axes_id == eje)
            const action = `se edito el analisis del eje '${axes.find(item => item.id === dataToSend.axes_id).name}'
            del instrumento ${instruments[0].name} de la empresa ${companiesList.find(item => item.id == dataToSend.company_id).name}, así quedo: '${dataToSend.analysis}'`
            Trazability(user.user.id, action)
            let res = await clienteAxios.post(`createOrUpdateAnalysis`, dataToSend);
            alert('analisis guardado')
        } catch (error) {
        }
    }



    const left = () => {
        let dataChartAux = [...dataChart]
        let aux = dataChartAux[dataChartAux.length - 1]
        dataChartAux.pop();
        dataChartAux.unshift(aux);
        let formvaluestAux = [...formvalues]
        let _aux = formvaluestAux[dataChartAux.length - 1]
        formvaluestAux.pop();
        formvaluestAux.unshift(_aux);

        let axestAux = [...axesButtons]
        let __aux = axestAux[dataChartAux.length - 1]
        axestAux.pop();
        axestAux.unshift(__aux);

        setformvalues(formvaluestAux);
        setDataChart(dataChartAux);
        setAxes(axestAux);
    }

    const right = () => {
        let dataChartAux = [...dataChart]
        let aux = dataChartAux[0]
        dataChartAux.shift();
        dataChartAux.push(aux);

        let formvaluestAux = [...formvalues]
        let _aux = formvaluestAux[0]
        formvaluestAux.shift();
        formvaluestAux.push(_aux);

        let axestAux = [...axesButtons]
        let __aux = axestAux[0]
        axestAux.shift();
        axestAux.push(__aux);

        setformvalues(formvaluestAux);
        setDataChart(dataChartAux);
        setAxes(axestAux);

    }

    const findNameAxes = (axes_id) => {
        let exist = axesButtons.find(item => item.id == axes_id)
        return exist.name
    }


    const selectRadar = (item) => {
        let position = axes.indexOf(item)

        let dataChartAux = [...dataChart]
        let aux = dataChartAux[position]
        dataChartAux.splice(position, 1);
        dataChartAux.unshift(aux);

        let formvaluestAux = [...formvalues]
        let _aux = formvaluestAux[position]
        formvaluestAux.splice(position, 1);
        formvaluestAux.unshift(_aux);

        let axestAux = [...axes]
        let __aux = axestAux[position]
        axestAux.splice(position, 1);
        axestAux.unshift(__aux);

        setformvalues(formvaluestAux);
        setDataChart(dataChartAux);
        setAxes(axestAux);
    }

    const changeCompany = (event) => {
        setDataChart([])
        setAxes('')
        setListaInstruments('')
        setCompanySelected(event.target.value)
        getInstruments(event.target.value)
    }

    const changeInstrument = (event) => {
        setDataChart([])
        setAxes('')
        setformvalues('')
        setInstrumentSelected(event.target.value)
        getAxes(event.target.value)
    }

    const sendDescription = async () => {
        try {
            var tmp = { ...description };
            tmp.id = companySelected
            let res = await clienteAxios.post(`descriptionCompany`, tmp);
            alert('descripción guardada')

        } catch (error) {

        }
    }

    const logout = () => {
        dispatch(logOut(user.user.id));
        history.push('/');
    }

    return (
        <div className="graphComponent">
            <div className='user'>
                <div style={{ marginRight: '10px' }}>{`${user.user.name} ${user.user.last_name}`}</div>
                <div onClick={logout} className="logOut">Salir</div>
            </div>
            <div className="radarHeader">
                <div>RADARES DE DIAGNÓSTICOS</div>
            </div>
            {auth ?
                <div>
                    {/* <div className="radarNameImage_"> */}
                    {(formvalues !== undefined && formvalues.length > 0 && axes !== '') &&
                        <div style={{ color: 'var(--blueThree)', letterSpacing: '3px', width: 'auto !important', display: 'inline-flex' }}>
                            <div className='leftTitle'></div>
                            <strong className="radarNameImage_">
                                {findNameAxes(formvalues[0].axes_id).toUpperCase()}
                            </strong>
                            <div className='rightTitle'></div>
                        </div>
                    }
                    {/* </div> */}
                    <div className="container">
                        {radarView &&
                            <div className="slects">
                                <div class="">
                                    <select style={{ width: '200px', marginBottom: '10px', marginTop: '20px' }} onChange={changeCompany}>
                                        <option >Seleccione empresa</option>
                                        <hr />
                                        {user.role[0].name === 'consultor' ?
                                            user.companies.length > 0 &&
                                            user.companies.map(item => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })
                                            :
                                            companiesList !== '' &&
                                            companiesList.map(item => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })
                                        }
                                        {/* {companiesList !== '' &&
                                            companiesList.map(item => {
                                                return <option value={item.id}>{item.name}</option>;
                                            })
                                        } */}
                                    </select>
                                </div>
                                {companySelected !== '' &&
                                    <div class="">
                                        <select style={{ width: '200px' }} onChange={changeInstrument}>
                                            <option >Seleccione instrumento</option>
                                            <hr />
                                            {(instruments !== '' && instruments !== undefined) &&
                                                instruments.map(item => {
                                                    return <option value={item.id}>{item.name}</option>;
                                                })
                                            }
                                        </select>
                                    </div>
                                }

                            </div>
                        }
                        <br />
                        <div style={{ width: '80%' }}>
                            <div onClick={() => setPdfModalopen(true)} className="download"></div>

                            {dataChart.length > 0 ?
                                <div>
                                    <div className="">
                                        <div className="radarAndAnalisys">
                                            <div className="beforeRadar" >

                                            </div>
                                            <div className="radar">
                                                <div>Media: {dataChart[0].media.toFixed(2)}</div>
                                                <Radar data={dataChart[0].datos} options={options} />
                                            </div>

                                            <div className="nextRadar"  ></div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="emptyData">Todavía no tenemos datos para graficar este radar.</div>
                            }
                            <div className="axesBox">
                                {axesButtons !== '' &&
                                    axesButtons.map(item => {
                                        return (<div onClick={() => selectRadar(item)} className="axesButton">
                                            <div>{item.name}</div>
                                        </div>)
                                    })
                                }
                            </div>
                        </div>
                        <div className="analisysSection">
                            <div style={{ color: 'var(--blueThree)', marginTop: '20px', marginLeft: '10px', textAlign: 'start' }}><strong>ANÁLISIS</strong></div>
                            {formvalues.length > 0 &&
                                <div >
                                    <div className="analisysText">
                                        {consultor ?
                                            <div>
                                                <div>
                                                    <form style={{ display: 'block' }}>
                                                        <textarea style={{ width: '90%', fontFamily: 'Roboto', color: 'gray' }} onChange={hadleInputChange} name={`analysis${formvalues[0].axes_id}`} value={formvalues[0].analysis} cols="10" rows="10">
                                                        </textarea>
                                                        <div onClick={() => sendData(axes[0].id)} className="saveAnalisys">
                                                        </div>
                                                    </form>
                                                </div>
                                                <hr />
                                                <div style={{ color: 'var(--blueThree)', marginTop: '20px', marginLeft: '10px', textAlign: 'start' }}><strong>DESCRIPCIÓN EMPRESA</strong></div>
                                                <div>
                                                    <form style={{ display: 'block' }}>
                                                        <textarea style={{ width: '90%', fontFamily: 'Roboto', color: 'gray' }} onChange={hadleInputChangeDescription} name={`description`} value={description.description} cols="40" rows="12">
                                                        </textarea>
                                                        <div onClick={() => sendDescription()} className="saveAnalisys">
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <textarea style={{ width: '90%', fontFamily: 'Roboto', color: 'gray' }} name={`analysis${formvalues[0].axes_id}`} value={formvalues[0].analysis} cols="40" rows="18"></textarea>
                                            </div>
                                        }
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>

                :
                <div>Token invalido</div>
            }
            <br />
            <br />

            <div>
                {dataChart.length == axesButtons.length &&
                    <div>
                        <PdfsModal dataChart={dataChart} formvalues={formvalues} pdfModalopen={pdfModalopen} axes={axesButtons} company={props.match.params.company} setPdfModalopen={setPdfModalopen} />
                    </div>
                }

            </div>
            <div className="footerRadar">
                <div>
                    © 2021 VTSAS-     Lantia. Todos los derechos reservados.
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <img style={{ width: '25px', height: '25px' }} src={require('../../../images/icons/mundo.png').default} alt="" />
                    www.vtsas.co
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <img style={{ width: '25px', height: '25px' }} src={require('../../../images/icons/twitter.png').default} alt="" />
                    vtsas
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <img style={{ width: '25px', height: '25px' }} src={require('../../../images/icons/linkedin.png').default} alt="" />
                    vtsas.co
                </div>
            </div>
        </div>
    )
}

export default PunctualRelationshipByCompany
