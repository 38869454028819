import React, { useState } from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

const DocuPDF = () => {

  const [imagen, setimagen] = useState('')

  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        setimagen(reader.result)
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  toDataURL('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0', function (dataUrl) {
  })

  const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";
  return (
    <div>
      { imagen !== '' &&
        <Document>
          <Page
            size="A4"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                padding: 10,
              }}
            >
              <Text style={{ color: "#3388af", fontSize: "42px" }}>
                {"hahah"}
              </Text>
              <Text>Por {"..."}</Text>
              <Image
                // src={/*require('../../../images/ui/background.jpg').default*/ imagen.default}
                style={{ maxWidth: "600px", maxHeight: "400" }}
              />
              <Text
                style={{
                  color: "gray",
                  fontStyle: "italic",
                  fontSize: "10px",
                }}
              >
                {lorem}
              </Text>

              <Text style={{ textAlign: "justify", marginTop: "22px" }}>
                {'jbdbchdbc'}
              </Text>
            </View>
          </Page>
        </Document>
      }
    </div>
  );
};

export default DocuPDF;
