import React, { useState } from 'react';
import Modal from 'react-modal';
import clienteAxios from '../../../config/axios';
import './axisModal.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#root');

export const AxisModal = ({ axisModalopen, setAxisModalopen, instrument, getInstrumentsWithAll }) => {

    //states
    const [formValues, setformValues] = useState('')
    const [error, setError] = useState('');

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setAxisModalopen(false);
        getInstrumentsWithAll();
    }

    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const sendData = async (event) => {
        event.preventDefault();
        let internalError = '';
        if (formValues.name === '' || formValues.name === undefined) {
            internalError = internalError + 'El nombre es requerido, ';
        }
        if (formValues.axes_id === '' || formValues.axes_id === undefined) {
            internalError = internalError + 'El radar es requerido, ';
        }
        if (internalError === '') {
            let response = await clienteAxios.post(`createComponent`, formValues);
            if (response.status === 200) {
                closeModal();
                alert('Eje creado con exito')
                setformValues('');
            }
            setError('');
        } else {
            setError(internalError);
        }
    }

    return (
        <div className="mainModal">
            <Modal
                isOpen={axisModalopen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="userModalBox">
                    <div className="userModalHeader">
                        <div>
                            <strong>Crear Eje</strong>
                        </div>
                        <div style={{ cursor: 'pointer', float: 'right' }} onClick={closeModal}><strong>X</strong></div>
                    </div>
                    <div className="userModalBody">
                        <form >
                            <div className="userFormItem">
                                <label>Nombre</label>
                                <input onChange={handleInputChange} type="text" value={formValues.name} name="name" />
                            </div>
                            <div className="col-12 col-sm-8 col-md-11 inputs">
                                <label>Radar</label>
                                <select name="axes_id" value={formValues.axes_id} onChange={handleInputChange}>
                                    <option value="">Seleccione radar</option>
                                    {instrument !== "" &&
                                        instrument.map(radar => {
                                            return <option value={radar.id}>{radar.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {error !== '' && <div style={{ width: '300px', color: 'red' }}>{error}</div>}
                            <div onClick={sendData} className="sendButton">Crear</div>
                        </form>
                    </div>
                </div>
            </Modal >
        </div>

    )
}
