import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { loginSuccess, setUser } from '../../../redux/actions/authAction';
import { useSelector } from 'react-redux';
import clienteAxios from '../../../config/axios';
import cookie from 'react-cookies';

//redux
import { useDispatch } from 'react-redux';


const ProtectedRoute = ({ ...props }) => {
    //componente prop
    const Component = props.component;
    const location = useLocation();

    //states
    const [wait, setWait] = useState(0);

    //redux
    const dispatch = useDispatch();
    const autenticated = useSelector(state => state.auth.autenticated);


    if (!cookie.load('token')) {


        return <Redirect to={{ pathname: '/' }} />
    }
    else if (autenticated) {
        return <Component {...props} />
    }
    else if (cookie.load('token')) {

        clienteAxios.get(`validateToken/${cookie.load('token')}`).then(response => {

            if (Date.parse(response.data.token[0].expiration) > Date.now()) {
                const maxAge = 3600 * 24 * 15;
                const expires = new Date()
                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                cookie.save('token', response.data.token[0].token, { path: "/", expires, maxAge });
                clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token[0];
                dispatch(loginSuccess(true));
                dispatch(setUser({
                    "message": response.data.token[0],
                    "user": response.data.user[0],
                    "role": response.data.role
                }));
                return <Component {...props} />
            } else {
                return <Redirect to={{ pathname: '/' }} />
            }
        });

        // let response = await clienteAxios.get(`validateToken/${cookie.load('token')}`);

        // if (Date.parse(response.data.token[0].expiration) > Date.now()) {
        //     const maxAge = 3600 * 24 * 15;
        //     const expires = new Date()
        //     expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
        //     cookie.save('token', response.data.token[0].token, { path: "/", expires, maxAge });
        //     clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token[0];
        //     dispatch(loginSuccess(true));
        //     dispatch(setUser({
        //         "message": response.data.token[0],
        //         "user": response.data.user[0],
        //         "role": response.data.role
        //     }));
        //     return <Component {...props} />
        // } else {
        //     return <Redirect to={{ pathname: '/' }} />
        // }
    }
    return <div>Cargando...</div>
}

export default ProtectedRoute;