import {
    SET_SCORE, SET_COMPONENTS
} from '../types/score';
import axios from 'axios';
import clienteAxios from '../../config/axios';


//consulta las calificaciones de los radares
export function getScores(axis, company) {
    return async (dispatch) => {
        try {
            let response = '';
            response = await clienteAxios.get(`scoreUsers/${axis}/${company}`);
            dispatch(setScore({ score: response.data.userScores, componentsNames: response.data.componentsNames }));

        } catch (error) {
        }
    }
}


//consulta las calificaciones de los radares
export function getScoresByUser(axis, userId) {
    return async (dispatch) => {
        try {
            let response = '';
            response = await clienteAxios.get(`scoresByUser/${axis}/${userId}`);
            dispatch(setScore({ score: response.data.userScores, componentsNames: response.data.componentsNames }));

        } catch (error) {
        }
    }
}


const setScore = (score) => ({
    type: SET_SCORE,
    payload: score
});


const setComponents = (components) => ({
    type: SET_COMPONENTS,
    payload: components
});