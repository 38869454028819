import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../redux/actions/userAction'
import clienteAxios from '../../../config/axios';
import './userModal.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#root');

export const UserModal = ({ userModalopen, setUserModalopen, userToEdit }) => {

    const dispatch = useDispatch();

    //redux
    const companies = useSelector(state => state.companies.companies);

    //states
    const [formValues, setformValues] = useState('')
    const [error, setError] = useState('');

    useEffect(() => {
        setformValues(userToEdit)
    }, [userToEdit])

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        dispatch(getUsers());
        setUserModalopen(false);
    }

    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const sendData = async (event) => {
        event.preventDefault();
        let internalError = '';
        if (formValues.name === '' || formValues.name === undefined) {
            internalError = internalError + 'El nombre es requerido, ';
        }
        if (formValues.last_name === '' || formValues.last_name === undefined) {
            internalError = internalError + 'El apellido es requerido, ';
        }
        if (formValues.cedula === '' || formValues.cedula === undefined) {
            internalError = internalError + 'La cedula es requerida, ';
        }
        if (formValues.email === '' || formValues.email === undefined) {
            internalError = internalError + 'el correo es requerida, ';
        }
        if (formValues.company_id === '' || formValues.company_id === undefined) {
            internalError = internalError + 'La empresa es requerida, ';
        }

        if (internalError === '') {
            let response = await clienteAxios.post(`createUser`, formValues);
            if (response.status === 200) {
                closeModal();
                if(response.data.hasOwnProperty('error')){
                    alert(response.data.error);
                }else{
                    if(formValues.hasOwnProperty('id')){
                        alert('usuario actualizado')
                    }else{
                        alert('usuario creado con exito')
                    }
                }
            }
            setError('');
        } else {
            setError(internalError);
        }
    }

    return (
        <div className="mainModal">
            <Modal
                isOpen={userModalopen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="userModalBox">
                    <div className="userModalHeader">
                        <div>
                            <strong>Gestion de Usuarios</strong>
                        </div>
                        <div style={{ cursor: 'pointer', float: 'right' }} onClick={closeModal}><strong>X</strong></div>
                    </div>
                    <div className="userModalBody">
                        <form >
                            <div className="userFormItem">
                                <label>Nombre</label>
                                <input onChange={handleInputChange} type="text" value={formValues.name} name="name" />
                            </div>
                            <div className="userFormItem">
                                <label>Apellido</label>
                                <input onChange={handleInputChange} type="text" value={formValues.last_name} name="last_name" />
                            </div>
                            <div className="userFormItem">
                                <label>Cedula</label>
                                <input onChange={handleInputChange} type="number" value={formValues.cedula} name="cedula" />
                            </div>
                            <div className="userFormItem">
                                <label>Correo</label>
                                <input onChange={handleInputChange} type="email" value={formValues.email} name="email" />
                            </div>
                            <div className="userFormItem">
                                <label htmlFor="">Empresa</label>
                                <select style={{ width: '200px' }} name="company_id" value={formValues.company_id} onChange={handleInputChange}>
                                    <option>Seleccione la empresa</option>
                                    {companies !== undefined &&
                                        companies.map(item => {
                                            return (<option value={item.id}>{item.name}</option>);
                                        })
                                    }
                                </select>
                            </div>

                            {error !== '' && <div style={{ width: '300px', color: 'red' }}>{error}</div>}
                            <div onClick={sendData} className="sendButton">Enviar</div>
                        </form>
                    </div>
                </div>
            </Modal >
        </div>

    )
}
