import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import clienteAxios from '../../config/axios';
import RutaNReporte from '../rutanReporte/RutaNReporte';

const Report = () => {

    const instruments = useSelector(state => state.instruments.instrumentsList);
    const [formvalues, setformvalues] = useState(Date.now)
    const [formvaluesNumberQuestions, setformvaluesNumberQuestions] = useState(Date.now)
    const [error, setError] = useState('');
    const [error2, setError2] = useState('');


    const hadleInputChange = (event) => {
        setformvalues({
            ...formvalues,
            [event.target.name]: event.target.value
        })
    }

    const hadleInputChange2 = (event) => {
        setformvaluesNumberQuestions({
            ...formvaluesNumberQuestions,
            [event.target.name]: event.target.value
        })
    }

    const sendData = async () => {
        let internalError = '';
        if (formvalues.instrumentId === '' || formvalues.instrumentId === undefined) {
            internalError = internalError + 'El instrumento es requerido, ';
        }
        if (formvalues.date === '' || formvalues.date === undefined) {
            internalError = internalError + 'La fecha es requerida, ';
        }
        
        if (internalError === '') {
            let response = await clienteAxios.get(`getReportScoreQuestionByUser/${formvalues.date}/${formvalues.instrumentId}`);
            if (response.status === 202){
                
                return window.alert('No existe reporte en el rango de fecha seleccionada.                         ' +
                '    Si desea puede intentar con una fecha inferior a la seleccionada.');
            }
            let xlsx = require('json-as-xlsx')
            let settings = {
                fileName: 'calificacionPreguntasPorUsuario', // Name of the spreadsheet
                extraLength: 30, // A bigger number means that columns will be wider
                writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
            }

            let data = [
                {
                    sheet: 'reporte',
                    columns: [
                        { label: 'fechaDiligenciamiento', value: 'created_at' },
                        { label: 'Nombre', value: 'name' }, // Top level data
                        { label: 'Apellido', value: 'last_name' }, // Run functions
                        { label: 'Empresa', value: 'Empresa' }, // Deep props
                        { label: 'Radar', value: 'Eje' }, // Deep props
                        { label: 'Componente', value: 'Componente' }, // Deep props
                        { label: 'Pregunta', value: 'question' },
                        { label: 'Calificación', value: 'score' }
                    ],
                    content: response.data.report
                }
            ]

            xlsx(data, settings)


            setError('');
        } else {
            setError(internalError);
        }
    }


    const sendData2 = async () => {
        let internalError = '';
        if (formvaluesNumberQuestions.instrument_id === '' || formvaluesNumberQuestions.instrument_id === undefined) {
            internalError = internalError + 'El instrumento es requerido, ';
        }
        if (formvaluesNumberQuestions.fecha === '' || formvaluesNumberQuestions.fecha === undefined) {
            internalError = internalError + 'La fecha es requerida, ';
        }

        if (internalError === '') {
            let response = await clienteAxios.get(`getReportNumberQuestionPerUser/${formvaluesNumberQuestions.fecha}/${formvaluesNumberQuestions.instrument_id}`);
            if (response.status === 202){
                
                return window.alert('No existe reporte en el rango de fecha seleccionada.                         ' +
                '    Si desea puede intentar con una fecha inferior a la seleccionada.');
            }
            console.log(response); //TODO borrar console

            let xlsx = require('json-as-xlsx')
            let settings = {
                fileName: 'calificacionPreguntasPorUsuario', // Name of the spreadsheet
                extraLength: 30, // A bigger number means that columns will be wider
                writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
            }

            let data = [
                {
                    sheet: 'reporte',
                    columns: [
                        { label: 'fechaDiligenciamiento', value: 'created_at' },
                        { label: 'Nombre', value: 'name' }, // Top level data
                        { label: 'Apellido', value: 'last_name' }, // Run functions
                        { label: 'Numero De Preguntas Diligenciadas', value: 'numeroDePreguntasDiligenciadas' } // Deep props
                    ],
                    content: response.data.report
                }
            ]

            xlsx(data, settings)

            setError2('');
        } else {
            setError2(internalError);
        }
    }


    return (
        <div style={{ marginTop: '50px' }}>
            <form >
                <div>
                    <div>Reporte de calificación de usuario por cada pregunta</div>
                    <label for="start">Mayor a esta fecha:</label>
                    <input onChange={hadleInputChange} type="date" id="start" name="date"
                        value={formvalues.date}
                    />
                </div>
                <div className="userFormItem">
                    <label htmlFor="">Instrumento</label>
                    <select style={{ width: '200px' }} name="instrumentId" value={formvalues.instrumentId} onChange={hadleInputChange}>
                        <option>Seleccione instrumento</option>
                        {instruments !== undefined &&
                            instruments.map(item => {
                                return (<option value={item.id}>{item.name}</option>);
                            })
                        }
                    </select>
                </div>
                {error !== '' && <div style={{ width: '300px', color: 'red' }}>{error}</div>}
                <div onClick={sendData} className="sendButton">Generar</div>
            </form>

            <form >
                <div>
                    <div>Reporte de número de preguntas diligenciadas por cada usuario</div>
                    <label for="start">Mayor a esta fecha:</label>
                    <input onChange={hadleInputChange2} type="date" id="start" name="fecha"
                        value={formvaluesNumberQuestions.fecha}
                    />
                </div>
                <div className="userFormItem">
                    <label htmlFor="">Instrumento</label>
                    <select style={{ width: '200px' }} name="instrument_id" value={formvaluesNumberQuestions.instrument_id} onChange={hadleInputChange2}>
                        <option>Seleccione instrumento</option>
                        {instruments !== undefined &&
                            instruments.map(item => {
                                return (<option value={item.id}>{item.name}</option>);
                            })
                        }
                    </select>
                </div>
                {error2 !== '' && <div style={{ width: '300px', color: 'red' }}>{error2}</div>}
                <div onClick={sendData2} className="sendButton">Generar</div>
            </form>
            <div>
                <RutaNReporte />
            </div>

        </div>
    )
}

export default Report
