import {
    SET_SCORE, SET_COMPONENTS, SET_CURRENT_SCORE
} from '../types/score';

//state
const initialState = {
    scores: {},
    componentsNames: []
}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_SCORE:
            return {
                ...state,
                scores: action.payload,
            }

        case SET_COMPONENTS:
            return {
                ...state,
                componentsNames: action.payload,
            }

        default:
            return state;
    }
}