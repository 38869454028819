import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Line } from "react-chartjs-2";
import clienteAxios from '../../../config/axios';
import './testAnalysis.css'

const TestAnalysis = () => {

    const instrument = useSelector(state => state.instruments.instrument);
    const user = useSelector(state => state.auth.user);
    const [dataChart, setDataChart] = useState('')
    const [profile, setProfile] = useState('')

    useEffect(() => {
        getAnswers(user.user.id, instrument[0].instrument_id);
    }, [])


    //obtener las respuestas guardadas
    const getAnswers = async (userId, instrumenId) => {
        try {
            let answ = await clienteAxios.get(`getResponsesToTest/${userId}/${instrumenId}`);
            //setAnswers(answ.data.answers)


            let components = []
            answ.data.answers.map(item => {
                components.push(item.name)
            })

            let uniq = [...new Set(components)];
            let arrayScores = [];
            uniq.forEach((item, index) => {
                let obj = {}
                let nameComponent = item.toString()
                obj[nameComponent] = 0;
                arrayScores.push(obj);
            });


            answ.data.answers.map((item, index) => {
                arrayScores.map(i => {
                    if (i.hasOwnProperty(item.name)) {
                        i[Object.keys(i)[0]] = i[Object.keys(i)[0]] + parseInt(item.score)
                    }
                })
            })

            let labels = [];
            let values = [];
            arrayScores.map(item => {
                labels.push(Object.getOwnPropertyNames(item)[0])
                values.push(item[Object.keys(item)[0]])
            });

            let total = 0;
            values.forEach(function (numero) {
                total += numero;
            });

            let media = (Math.max(...values) + Math.min(...values)) / 2

            let lineUp = media + 2.5;
            let lineDown = media - 2.5;

            let mayores = []
            arrayScores.map(item => {
                if (item[Object.keys(item)[0]] > lineUp) {
                    mayores.push(Object.keys(item)[0]);
                }
            });

            getProfile(mayores);

            const data = {
                labels: labels,
                datasets: [
                    {
                        label: "test",
                        data: values,
                        fill: true,
                        backgroundColor: "hsla(0, 0%, 0%, 0)",
                        borderColor: "rgba(75,192,192,1)"
                    },
                    {
                        label: "up",
                        data: Array.from({ length: values.length }, () => lineUp),
                        fill: true,
                        backgroundColor: "hsla(0, 0%, 0%, 0)",
                        borderColor: "rgb(128, 128, 0)"
                    },
                    {
                        label: "down",
                        data: Array.from({ length: values.length }, () => lineDown),
                        fill: true,
                        backgroundColor: "hsla(0, 0%, 0%, 0)",
                        borderColor: "rgb(255, 0, 0)"
                    }
                ]
            };

            setDataChart(data);

        } catch (error) {
        }
    }

    const getProfile = async (mayores) => {
        if (mayores.includes('Ideador') && mayores.length == 1) {
            let profile = await clienteAxios.get(`showProfileByName/Ideadores`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Clarificador') && mayores.length == 1) {
            let profile = await clienteAxios.get(`showProfileByName/Clarificadores`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Desarrollador') && mayores.length == 1) {
            let profile = await clienteAxios.get(`showProfileByName/Desarrolladores`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Implementador') && mayores.length == 1) {
            let profile = await clienteAxios.get(`showProfileByName/Implementadores`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Clarificador') && mayores.includes('Ideador')) {
            let profile = await clienteAxios.get(`showProfileByName/Madrugador`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Clarificador') && mayores.includes('Desarrollador')) {
            let profile = await clienteAxios.get(`showProfileByName/Analista`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Clarificador') && mayores.includes('Implementador')) {
            let profile = await clienteAxios.get(`showProfileByName/Acelerador`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Ideador') && mayores.includes('Desarrollador')) {
            let profile = await clienteAxios.get(`showProfileByName/Teórico`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Ideador') && mayores.includes('Implementador')) {
            let profile = await clienteAxios.get(`showProfileByName/Conductor`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Clarificador') && mayores.includes('Ideador') && mayores.includes('Desarrollador')) {
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Clarificador') && mayores.includes('Ideador') && mayores.includes('Implementador')) {
            let profile = await clienteAxios.get(`showProfileByName/Corredor de ideas`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Clarificador') && mayores.includes('Desarrollador') && mayores.includes('Implementador')) {
            let profile = await clienteAxios.get(`showProfileByName/Realista`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Ideador') && mayores.includes('Desarrollador') && mayores.includes('Implementador')) {
            let profile = await clienteAxios.get(`showProfileByName/Optimista`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Desarrollador') && mayores.includes('Implementador')) {
            let profile = await clienteAxios.get(`showProfileByName/Finalizador`);
            setProfile(profile.data[0]);
        }
        if (mayores.includes('Ideador') && mayores.includes('Desarrollador') && mayores.includes('Implementador') && mayores.includes('Clarificador')) {
            let profile = await clienteAxios.get(`showProfileByName/Integrador`);
            setProfile(profile.data[0]);
        }
    }

    return (
        <div>
            {dataChart !== '' &&
                <div className="row">
                    <div style={{ backgroundColor: '#fff', marginBottom: '100px' }} className="col-12 -col-md-6 col-lg-6 offset-3">
                        <Line data={dataChart} />
                    </div>
                </div>
            }
            {profile !== '' &&
                <div className="profile">
                    <div>
                        <h4>{profile.name}</h4>
                    </div>
                    <div className="profileDescription">
                        {profile.description}
                    </div>
                    <div className="profilePreference">
                        <p><strong>Preferencias del perfil</strong></p>
                        {profile.preferences.split('•').map((item, index) => {
                            return (<div>
                                {index > 0 && '•'}{item}
                            </div>)
                        })}
                    </div>
                    <div className="profileFeatures">
                        <p><strong>Características</strong></p>
                        {profile.features.split('•').map((item, index) => {
                            return (<div>
                                {index > 0 && '•'}{item}
                            </div>)
                        })}
                    </div>
                    <div className="phrasesProfile">
                        <p><strong>Frases</strong></p>
                        {profile.phrases}
                    </div>
                    <div className="profileinconvenience">
                        <p><strong>A las demás personas les puede molestar de tu perfil</strong></p>
                        {profile.inconvenience.split('•').map((item, index) => {
                            return (<div>
                                {index > 0 && '•'}{item}
                            </div>)
                        })}
                    </div>
                    <div className="perfilesParecidos">
                        <p><strong>Perfíles famosos similares a ti</strong></p>
                        {profile.famous_profile}
                    </div>
                    <div className="profileinconvenience">
                        <p><strong>Este perfil requiere para trabajar</strong></p>
                        {profile.requirements.split('•').map((item, index) => {
                            return (<div>
                               {index > 0 && '•'} {item}
                            </div>)
                        })}
                    </div>
                    <div className="perfilesParecidos">
                        <p><strong>Aproveche al máximo sus preferencias de pensamiento</strong></p>
                        {profile.take_advantage}
                    </div>
                    <div className="perfilesParecidos">
                        <p><strong>Un ejemplo de como funciona este perfil</strong></p>
                        {profile.example}
                    </div>
                </div>
            }
        </div>
    )
}

export default TestAnalysis
