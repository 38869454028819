import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import clienteAxios from '../../../config/axios';
import { getAllInstruments } from '../../../redux/actions/instrumentAction';
import './instrumentModal.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#root');

export const InstrumentModal = ({ instrumentModalopen, setInstrumentModalopen }) => {

    //states
    const [formValues, setformValues] = useState('')
    const [error, setError] = useState('');

    const dispatch = useDispatch();

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        dispatch(getAllInstruments());
        setInstrumentModalopen(false);
    }

    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const sendData = async (event) => {
        event.preventDefault();
        let internalError = '';
        if (formValues.name === '' || formValues.name === undefined) {
            internalError = internalError + 'El nombre es requerido, ';
        }
        if (internalError === '') {
            formValues.welcome_text = '.';
            formValues.farewell_text = '.';
            formValues.type = 1;
            let response = await clienteAxios.post(`createInstrument`, formValues);
            if (response.status === 200) {
                closeModal();
                alert('Instrumento creado')
            }
            setError('');
        } else {
            setError(internalError);
        }
    }

    return (
        <div className="mainModal">
            <Modal
                isOpen={instrumentModalopen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="userModalBox">
                    <div className="userModalHeader">
                        <div>
                            <strong>Crear Instrumento</strong>
                        </div>
                        <div style={{ cursor: 'pointer', float: 'right' }} onClick={closeModal}><strong>X</strong></div>
                    </div>
                    <div className="userModalBody">
                        <form >
                            <div className="userFormItem">
                                <label>Nombre</label>
                                <input onChange={handleInputChange} type="text" value={formValues.name} name="name" />
                            </div>
                            {error !== '' && <div style={{ width: '300px', color: 'red' }}>{error}</div>}
                            <div onClick={sendData} className="sendButton">Crear</div>
                        </form>
                    </div>
                </div>
            </Modal >
        </div>

    )
}
