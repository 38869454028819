import React, { useEffect, useState } from "react";
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({

  inline: {
    display: "flex",
    justifyContent: 'space-between',
    flexDirection: "row",
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '10px',
    marginBottom: '50px'
  },
  radar: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  footer: {
    position:"fixed",
    bottom: 10,
    display: "flex",
    justifyContent: 'center',
    flexDirection: "row",
    marginBottom: '10px',
    zIndex: '10'
  },
  body: {
    height:'500px',
    marginRight: '20px',
    marginLeft: '20px',
    marginBottom: '300px'
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  analisis: {
    fontSize: '10px',
    marginLeft: '80px',
    marginRight: '80px',
    textAlign: 'justify'
  }
});

const DocuPDF = ({ imagesCharts, formvalues, axes, company }) => {
  const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";


  return (
    <Document>
      <Page
        size="A4"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
        }}
        wrap>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "white",
            padding: 0,
          }}
        >
          <View fixed>
            <div style={styles.inline}>
              <Image
                src={require('../../../images/ui/valle.png').default}
                style={{ width: "120px", height: "50px" }}
              />
              {/* <Image
                src={require('../../../images/ui/impulsa.png').default}
                style={{ width: "120px", height: "40px" }}
              /> */}
            </div>

          </View>

          <Text style={styles.title}>{company}</Text>

          {
            imagesCharts.map((item, index) => {
              return (<div style={styles.body}>
                <View>
                  <Text style={styles.title}>{axes[index].name}</Text>
                  <Text style={styles.title}>Media: {item.media.toFixed(2)}</Text>
                  <div></div>
                  <Image
                    src={item.image}
                    alt="random image"
                    style={{ width: "500px", height: "400px", marginLeft: 'auto', marginRight: 'auto', paddingBottom: '20px' }}
                  />
                  <Text break style={styles.analisis}>{formvalues[index].analysis}</Text>
                </View>
              </div>
              )
            })
          }
          <View fixed>
            <div style={styles.footer}>
              <Image
                src={require('../../../images/ui/footerValle.png').default}
                style={{ width: "400px", height: "80px", float: 'right' }}
              />
            </div>
            <div style={{ width: '100%', height: '10px', backgroundColor: '#540FE1' }}></div>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DocuPDF;
