import React, { useState } from 'react'
import Questions from './questions/Questions'
import PunctualRelationship from './punctualRelationship/PunctualRelationship'
import './instrument.css'

const Instrument = () => {

    const [tab, setTab] = useState(1)
    
    return (
        <div className="instrumentComponent">
            <div className="navbar d-flex  justify-content-end">
                <div className="logOut">Salir</div>
            </div>
            <div className="instrumentBody">
                <div className="tabs">
                    <div onClick={() => setTab(1)} className="tabItem">Preguntas</div>
                    <div onClick={() => setTab(2)} className="tabItem">Relacion Puntuación</div>
                </div>
                {tab === 1 &&
                    <Questions />
                }
                {tab === 2 &&
                    <PunctualRelationship />
                }
            </div>
        </div>
    )
}

export default Instrument
