import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInstruments, selectInstrument, getAxes } from '../../redux/actions/instrumentAction';
import clienteAxios from '../../config/axios';
import { logOut } from '../../redux/actions/authAction';
import './diagnosisDashboard.css'

/*componente donde semuestra la lista de 
los instrumentos que corresponde al usuario que ingreso al sistema*/
const DiagnosisDashboard = () => {
    const instruments = useSelector(state => state.instruments.instrumentsList);
    const user = useSelector(state => state.auth.user);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user !== undefined && Object.keys(user).length !== 0) {
            dispatch(getInstruments(user.user.company_id));
        }
    }, [user])

    //selecciona el instrumento y hace una 
    //consulta por medio de redux para traer los ejes del instrumento
    const goInstrument = (instrument) => {
        dispatch(selectInstrument(instrument));
        dispatch(getAxes(instrument.id));
        if (user.role[0].name === 'admin') {
            history.push(`/punctualRelationshipAdmin`);
            return;
        }
        if (instrument.id == 7) {
            getUserInfo(user.user.id);
            return;
        }
        history.push(`/poll`);

    }

    const getUserInfo = async (userId) => {
        try {
            let response = await clienteAxios.get(`getUserAddInfo/${userId}`);
            if (response.data.userRegisterExist.length == 0) {
                history.push(`/aditionalUserInfo`);
                return;
            }

            history.push(`/poll`);

        } catch (error) {

        }
    }

    const createInstrument = () => {
        history.push(`/AdminDashboard`);
    }

    const logout = () => {
        dispatch(logOut(user.user.id));
        history.push('/');
    }

    return (
        <div className="diagnosisDashboardComponent">
            <div className="instrumentsList">
                <div className="instrumentsBox">
                    {(instruments !== undefined && instruments.length !== 0) &&
                        instruments.map(instrument => {
                            return <div onClick={() => goInstrument(instrument)} className="instrument"><div className="instrumentName">{instrument.name}</div></div>;
                        })
                    }
                </div>
            </div>
            <div onClick={logout} className="logOut" style={{marginTop:'10px', backgroundColor:'#fff', color:'gray', transform:'translate(321%, 10px)'}}>Salir</div>
            {/* {user.role[0].name === 'admin' &&
                <div className="createInstrument" onClick={createInstrument}></div>
            } */}
        </div>
    )
}

export default DiagnosisDashboard
