import React, { useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import DiagnosisDashboard from './components/main/DiagnosisDashboard'
import Instrument from './components/instrument/Instrument'
import Login from './components/login/Login'
import Poll from './components/poll/Poll'
import ProtectedRoute from './components/auth/routes/ProtectedRoute'
import PunctualRelationshipAdmin from './components/instrument/punctualRelationshipAdmin/PunctualRelationshipAdmin';
import BBB from './components/meeting/BBB';
import AdminDashboard from './components/adminDashboard/AdminDashboard';
import InstrumentManagment from './components/adminDashboard/instrumentManagment/InstrumentManagment';
import PunctualRelationshipByCompany from './components/instrument/punctualRelationshipByCompany/PunctualRelationshipByCompany';
import Report from './components/report/Report';
import AditionalUserInfo from './components/instrument/aditionalUserInfo/AditionalUserInfo';
import SatisfactionSurvey from './components/satisfactionSurvey/SatisfactionSurvey';
import SatisfactionSurveyEscuelaVt from './components/satisfactionSurveyEscuelaVt/SatisfactionSurveyEscuelaVt';
import SatisfactionSurveyProspective from './components/satisfactionSurveyProspective/SatisfactionSurveyProspective';
import SatisfactionSurveyVigilancia from './components/satisfactionSurveyVigilancia/SatisfactionSurveyVigilancia';
import Trazability from './components/trazability/Trazability';
import './App.css';

import { Provider } from 'react-redux';
import store from './redux/store';

function App() {

  const [meet, setmeet] = useState(false)
  const [max, setMax] = useState(false)

  const meeting = () => {
    // history.push('/meeting');
    setmeet(!meet);
  }

  const maxMeet = () => {
    setMax(!max)
  }

  const closeMeet = () => {
    setmeet(!meet);
  }

  return (
    <div className="App">
      {/* <div onClick={meeting} className="buttonMeet">

      </div> */}
      {/* {meet &&
        <div style={{ width: `${max ? '90%' : '410px'}`, height: `${max ? '700px' : '430px'}`, marginTop: `${max ? '0px' : '200px'}` }} className="meet">
          <div className="meetHeader">
            <div className="meetHeaderMenu">
              <div onClick={maxMeet} className="meetHeaderMenuItem">{max ? 'minimizar' : 'maximizar'}</div>
              <div onClick={closeMeet} className="meetHeaderMenuItem">cerrar</div>
            </div>
          </div>
          <BBB width={max ? '98%' : '400px'} height={max ? '650px' : '400px'} marginTop={max ? '0px' : '200px'} />
        </div>
      } */}
      <BrowserRouter>
        <Provider store={store}>
          <Switch>
            <ProtectedRoute exact path="/instrument" component={Instrument} />
            <ProtectedRoute exact path="/poll" component={Poll} />
            <ProtectedRoute exact path="/diagnosisDashboard" component={DiagnosisDashboard} />
            <ProtectedRoute exact path="/punctualRelationshipAdmin" component={PunctualRelationshipAdmin} />
            <ProtectedRoute exact path="/adminDashboard" component={AdminDashboard} />
            <ProtectedRoute exact path="/instrumentManagment/:id" component={InstrumentManagment} />
            <ProtectedRoute exact path="/report" component={Report} />
            <ProtectedRoute exact path="/trazability" component={Trazability} />
            <ProtectedRoute exact path="/aditionalUserInfo" component={AditionalUserInfo} />
            <Route exact path="/:token?" component={Login} /> 
            <Route exact path="/punctualRelationshipByCompany/:company/:instrument/:token" component={PunctualRelationshipByCompany} />
            <Route exact path="/encuesta/satisfaccion" component={SatisfactionSurvey} />
            <Route exact path="/encuesta/satisfaccionVigilancia" component={SatisfactionSurveyVigilancia} />
            <Route exact path="/encuesta/satisfaccionEscueltaVt" component={SatisfactionSurveyEscuelaVt} />
            <Route exact path="/encuesta/satisfaccionProspectiva" component={SatisfactionSurveyProspective} />
          </Switch>
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
