import React, { useState, useEffect } from 'react'
import './aditionalUserInfo.css'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { cleanInstrument } from '../../../redux/actions/instrumentAction';
import clienteAxios from '../../../config/axios';

const AditionalUserInfo = () => {

    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [formValues, setformValues] = useState('');
    const [listas, setlistas] = useState('')
    const [capacitacionTemas, setCapacitacionTemas] = useState([]);
    const user = useSelector(state => state.auth.user);
    const history = useHistory();


    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        try {
            let response = await clienteAxios.get(`listas`);
            setlistas(response.data)
        } catch (error) {

        }
    }

    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const validateData = async (event) => {
        event.preventDefault();

        let internalError = '';
        if (formValues.date_of_birth === '' || formValues.date_of_birth === undefined) {
            internalError = 'La fecha de nacimiento es requerida, ';
        }
        if (formValues.identity_doc === '' || formValues.identity_doc === undefined) {
            internalError = 'El tipo de identificación es requerido, ';
        }
        if (formValues.name === '' || formValues.name === undefined) {
            internalError = 'El nombre es requerido, ';
        }
        if (formValues.last_name === '' || formValues.last_name === undefined) {
            internalError = 'El apellido es requerido, ';
        }
        if (formValues.doc_type === '' || formValues.doc_type === undefined) {
            internalError = internalError + 'El tipo de documento es requerido, ';
        }
        if (formValues.sex === '' || formValues.sex === undefined) {
            internalError = internalError + 'El sexo es requerido, ';
        }
        if (formValues.adress === '' || formValues.adress === undefined) {
            internalError = internalError + 'La dirección es requerida, ';
        }
        if (formValues.barrio_vereda === '' || formValues.barrio_vereda === undefined) {
            internalError = internalError + 'El barrio o vereda es requerido, ';
        }
        if (formValues.estrato === '' || formValues.estrato === undefined) {
            internalError = internalError + 'El estrato es requerido, ';
        }
        if (formValues.lgtbi === '' || formValues.lgtbi === undefined) {
            internalError = internalError + 'El campo de lgtbi es requerido, ';
        }
        if (formValues.etnia === '' || formValues.etnia === undefined) {
            internalError = internalError + 'La etnia es requerida, ';
        }
        if (formValues.discapacidad === '' || formValues.discapacidad === undefined) {
            internalError = internalError + 'El campo discapacidad es requerido, ';
        }
        if (formValues.victima === '' || formValues.victima === undefined) {
            internalError = internalError + 'El campo victima es requerido, ';
        }
        if (formValues.poblacion_campesina === '' || formValues.poblacion_campesina === undefined) {
            internalError = internalError + 'El campo poblacion campesina es requerido, ';
        }
        if (formValues.estado_civil === '' || formValues.estado_civil === undefined) {
            internalError = internalError + 'El campo del estado civil es requerido, ';
        }
        if (formValues.numero_empleados === '' || formValues.numero_empleados === undefined) {
            internalError = internalError + 'El campo del número de empleados de la empresa es requerido, ';
        }
        if (formValues.nivel_escolaridad === '' || formValues.nivel_escolaridad === undefined) {
            internalError = internalError + 'El nivel de escolaridad es requerido, ';
        }
        if (formValues.victima === '' || formValues.victima === undefined) {
            internalError = internalError + 'El campo victima es requerido, ';
        }
        if (formValues.cabeza_hogar === '' || formValues.cabeza_hogar === undefined) {
            internalError = internalError + 'El campo cabeza de hogar es requerido, ';
        }
        if (formValues.categoria_ocupacion === '' || formValues.categoria_ocupacion === undefined) {
            internalError = internalError + 'De acuerdo a su ocupación seleccione a que categoria pertenece es requerido, ';
        }
        if (formValues.empleado_empresa === '' || formValues.empleado_empresa === undefined) {
            internalError = internalError + 'La pregunta: Si considera que pertenece a la categoria de emprendedor, indique si actualmente se encuentra vinculado en calidad de empleado en alguna organización es requerida, ';
        }
        if (formValues.macrosector_id === '' || formValues.macrosector_id === undefined) {
            internalError = internalError + 'El macrosector es requerido, ';
        }
        if (formValues.subsector_id === '' || formValues.subsector_id === undefined) {
            internalError = internalError + 'El subsector es requerido, ';
        }
        if (formValues.company_nit === '' || formValues.company_nit === undefined) {
            internalError = internalError + 'El Nit de la empresa es requerido, ';
        }
        if (formValues.company_name === '' || formValues.company_name === undefined) {
            internalError = internalError + 'El campo cabeza de hogar es requerido, ';
        }
        if (formValues.company_type === '' || formValues.company_type === undefined) {
            internalError = internalError + 'La Naturaleza Jurídica es requerida, ';
        }
        if (formValues.products_services_offered === '' || formValues.products_services_offered === undefined) {
            internalError = internalError + 'El campo de productos o servicios ofrecidos es requerido, ';
        }
        if (formValues.import_products_services === '' || formValues.import_products_services === undefined) {
            internalError = internalError + 'El campo de importación de productos o servicios es requerido, ';
        }
        if (formValues.exporta_productos_servicios === '' || formValues.exporta_productos_servicios === undefined) {
            internalError = internalError + 'El campo de exportación de productos o servicios es requerido, ';
        }
        if (formValues.empresa_pertenece_red === '' || formValues.empresa_pertenece_red === undefined) {
            internalError = internalError + 'la empresa pertenece a una red es requerida, ';
        }
        if (formValues.empresa_asociada_upa === '' || formValues.empresa_asociada_upa === undefined) {
            internalError = internalError + 'la empresa esta asociada a una upa es requerido, ';
        }
        if (formValues.empresa_pertenece_cluster === '' || formValues.empresa_pertenece_cluster === undefined) {
            internalError = internalError + 'la empresa pertenece a un cluster es requerido, ';
        }
        if (formValues.promedio_anual_ventas === '' || formValues.promedio_anual_ventas === undefined) {
            internalError = internalError + 'Promedio anual de ventas es requerido, ';
        }
        if (formValues.company_adress === '' || formValues.company_adress === undefined) {
            internalError = internalError + 'la dirección de la empresa es requerida, ';
        }
        if (formValues.comuna_id === '' || formValues.comuna_id === undefined) {
            internalError = internalError + 'la comuna es requerida, ';
        }
        if (formValues.barrio_company === '' || formValues.barrio_company === undefined) {
            internalError = internalError + 'el barrio de la empresa es requerido, ';
        }
        if (formValues.telefono_empresa === '' || formValues.telefono_empresa === undefined) {
            internalError = internalError + 'el telefono de la empresa es requerido, ';
        }
        if (formValues.movil_empresa === '' || formValues.movil_empresa === undefined) {
            internalError = internalError + 'el telefono móvil de la empresa es requerido, ';
        }
        if (formValues.correo_empresa === '' || formValues.correo_empresa === undefined) {
            internalError = internalError + 'el correo de la empresa es requerido, ';
        }
        if (formValues.temas_recibir_capacitacion === '' || formValues.temas_recibir_capacitacion === undefined) {
            internalError = internalError + 'temas que desea recibir capacitación es requerido, ';
        }
        if (internalError === '') {
            setError('');
            formValues.user_id = user.user.id
            let response = await clienteAxios.post(`addInfo`, formValues);
            if (!response.data.hasOwnProperty('error')) {
                history.push(`/poll`);
            }

        } else {
            alert(internalError)
            setError(internalError);
        }
    }


    const capacitacion = (event) => {
        if (event.target.value === 'Ninguna') {
            setCapacitacionTemas([])
            formValues.temas_recibir_capacitacion = '';
            setformValues(formValues)
            var x = document.getElementsByClassName("tema");
            for (var i = 0; i < x.length; i++) {
                x[i].checked = false;
            }
            return;
        } else {
            var x = document.getElementsByClassName("nada");
            x[0].checked = false;
        }
        let exist = capacitacionTemas.find(item => item == event.target.value)
        if (exist !== undefined) {
            setCapacitacionTemas(capacitacionTemas.filter(theme => exist !== theme))
            let capacit = '';
            capacitacionTemas.filter(theme => exist !== theme).map(item => {
                capacit = capacit + item;
            })
            formValues.temas_recibir_capacitacion = capacit;
            setformValues(formValues)
            return;
        }
        setCapacitacionTemas([...capacitacionTemas, event.target.value])
        let capacit = '';
        [...capacitacionTemas, event.target.value].map(item => {
            capacit = capacit + item;
        })
        formValues.temas_recibir_capacitacion = capacit;
        setformValues(formValues)

    }

    const goMenu = () => {
        dispatch(cleanInstrument());
        history.push('/diagnosisDashboard');
    }

    return (
        <div className="aditionalUserForm">
            <div onClick={goMenu} className="comeBackMenu">volver al menu de diagnosticos</div>
            <form>
                <div className="groupFields">
                    <h4><strong>FORMULARIO DE CARACTERIZACIÓN EMPRESARIAL Y DE CIUDADANO</strong></h4>
                    <br />
                    <br />
                    <div className="field">
                        <div className="insideField">
                            <label>Tipo de identificación</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="doc_type" value={formValues.doc_type} onChange={handleInputChange}>
                                <option value="">Seleccione tipo de identificación</option>
                                <option value="Cédula de extranjería">Cédula de extranjería</option>
                                <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                                <option value="Tarjeta de identidad">Tarjeta de identidad</option>
                                <option value="Registro civil">Registro civil</option>
                                <option value="Registro civil">Pasaporte</option>
                                <option value="Certificado diplomático">Certificado diplomático</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Número de identificación</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.identity_doc} type="text" name="identity_doc" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Nombre</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.name} type="text" name="name" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Apellido</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.last_name} type="text" name="last_name" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Fecha de nacimiento</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.date_of_birth} type="date" name="date_of_birth" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Sexo</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="sex" value={formValues.sex} onChange={handleInputChange}>
                                <option value="">Seleccione tipo de identificación</option>
                                <option value="Masculino">Masculino</option>
                                <option value="Femenino">Femenino</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Dirección</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.adress} type="text" name="adress" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Comuna</label>
                        </div>
                        <div className="insideField">
                            <div className="insideField">
                                {listas !== '' &&
                                    <select className="inputField" name="comuna_id" value={formValues.comuna_id} onChange={handleInputChange}>
                                        <option value="">Seleccione la comuna</option>
                                        {listas.comunas.map(item => {
                                            return (<option value={item.id}>{item.name}</option>)
                                        })
                                        }
                                    </select>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Barrio o vereda</label>
                        </div>
                        <div className="insideField">
                            {listas !== '' &&
                                <select className="inputField" name="barrio_vereda" value={formValues.barrio_vereda} onChange={handleInputChange}>
                                    <option value="">Seleccione barrio</option>
                                    {listas.barrios.map(item => {
                                        return (<option value={item.id}>{item.name}</option>)
                                    })
                                    }
                                </select>
                            }

                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Estrato</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.estrato} type="text" name="estrato" />
                        </div>
                    </div>
                </div>
                <div className="groupFields">
                    <div className="field">
                        <div className="insideField">
                            <label>LGTBI</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="lgtbi" value={formValues.lgtbi} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="field">
                        <div className="insideField">
                            <label>Etnia</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="etnia" value={formValues.etnia} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Indigena</option>
                                <option value="No">Otros</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Discapacidad</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="discapacidad" value={formValues.discapacidad} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Victima</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="victima" value={formValues.victima} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Población campesina</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="poblacion_campesina" value={formValues.poblacion_campesina} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Estado Civil</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="estado_civil" value={formValues.estado_civil} onChange={handleInputChange}>
                                <option value="">seleccione estado civil</option>
                                <option value="casado">casado</option>
                                <option value="viudo">viudo</option>
                                <option value="union libre">union libre</option>
                                <option value="divorciado">divorciado</option>
                                <option value="soltero">soltero</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Nivel de escolaridad</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="nivel_escolaridad" value={formValues.nivel_escolaridad} onChange={handleInputChange}>
                                <option value="">seleccione estado civil</option>
                                <option value="Bachiller">Bachiller</option>
                                <option value="tecnico">tecnico</option>
                                <option value="Tecnologo">Tecnologo</option>
                                <option value="Profesional">Profesional</option>
                                <option value="Especialista">Especialista</option>
                                <option value="Magister">Magister</option>
                                <option value="Doctor">Doctor</option>
                                <option value="postdoctor">postdoctor</option>
                                <option value="Ninguno">Ninguno</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Cabeza de Hogar</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="cabeza_hogar" value={formValues.cabeza_hogar} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>

                    </div>
                </div>
                <div className="groupFields">
                    <div  style={{width:'100% !important', display:'inline-flex', padding:'50px'}}>
                        <div style={{width:'50%', textAlign:'start'}}>
                            <label>De acuerdo a su ocupación seleccione a que categoria pertenece</label>
                        </div>
                        <div style={{width:'50%'}}>
                            <select className="inputField" name="categoria_ocupacion" value={formValues.categoria_ocupacion} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Emprendedor">Emprendedor</option>
                                <option value="Otro">Otro</option>
                            </select>
                        </div>
                    </div>
                    <div  style={{width:'100% !important', display:'inline-flex', padding:'50px'}}>
                        <div className="insideField">
                            <label>Si considera que pertenece a la categoria de emprendedor, indique si actualmente se encuentra vinculado en calidad de empleado en alguna organización</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="empleado_empresa" value={formValues.empleado_empresa} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div style={{width:'100% !important', display:'inline-flex', padding:'50px'}}>
                        <div className="insideField">
                            <label>En caso de que la respuesta a la pregunta anterior sea afirmativa, indique el nombre de la organización para la cuál trabaja</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.company_name_work} type="text" name="company_name_work" id="" />
                        </div>
                    </div>
                </div>
                <div className="groupFields">

                    <div className="field">
                        <div className="insideField">
                            <label>Nombre de la empresa</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.company_name} type="text" name="company_name" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Nit de la empresa</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.company_nit} type="text" name="company_nit" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Número de empleados</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.numero_empleados} type="text" name="numero_empleados" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Naturaleza Jurídica</label>
                        </div>
                        <div className="insideField">
                            {listas !== '' &&
                                <select className="inputField" name="company_type" value={formValues.company_type} onChange={handleInputChange}>
                                    <option value="">Seleccione Naturaleza Jurídica</option>
                                    {listas.companyTypes.map(item => {
                                        return (<option value={item.id}>{item.name}</option>)
                                    })
                                    }
                                </select>
                            }

                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Seleccione el macrosector al que pertenece su emprendimiento</label>
                        </div>
                        <div className="insideField">
                            {listas !== '' &&
                                <select className="inputField" name="macrosector_id" value={formValues.macrosector_id} onChange={handleInputChange}>
                                    <option value="">Seleccione Macrosector</option>
                                    {listas.macrosectors.map(item => {
                                        return (<option value={item.id}>{item.name}</option>)
                                    })
                                    }
                                </select>
                            }
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Seleccione el subsector al que pertenece su emprendimiento</label>
                        </div>
                        <div className="insideField">
                            {listas !== '' &&
                                <select className="inputField" name="subsector_id" value={formValues.subsector_id} onChange={handleInputChange}>
                                    <option value="">Seleccione subsector</option>
                                    {listas.subsectors.map(item => {
                                        return (<option value={item.id}>{item.name}</option>)
                                    })
                                    }
                                </select>
                            }
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Productos/Servicios que ofrece</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.products_services_offered} type="text" name="products_services_offered" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>¿En el desarrollo de sus actividades, importa productos o servicios?</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="import_products_services" value={formValues.import_products_services} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>¿En el desarrollo de sus actividades, exporta productos o servicios?</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="exporta_productos_servicios" value={formValues.exporta_productos_servicios} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>¿La empresa pertenece a una red?</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="empresa_pertenece_red" value={formValues.empresa_pertenece_red} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>¿La empresa esta asociada a una upa?</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="empresa_asociada_upa" value={formValues.empresa_asociada_upa} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>¿La pertenece a un cluster?</label>
                        </div>
                        <div className="insideField">
                            <select className="inputField" name="empresa_pertenece_cluster" value={formValues.empresa_pertenece_cluster} onChange={handleInputChange}>
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Indique el valor de las ventas generadas en el último año o el promedio anual de ventas</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.promedio_anual_ventas} type="number" name="promedio_anual_ventas" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Dirección principal de la empresa</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.company_adress} type="text" name="company_adress" id="" />
                        </div>
                    </div>

                    <div className="field">
                        <div className="insideField">
                            <label>Barrio de la empresa</label>
                        </div>
                        <div className="insideField">
                            {listas !== '' &&
                                <select className="inputField" name="barrio_company" value={formValues.barrio_company} onChange={handleInputChange}>
                                    <option value="">Seleccione barrio</option>
                                    {listas.barrios.map(item => {
                                        return (<option value={item.id}>{item.name}</option>)
                                    })
                                    }
                                </select>
                            }
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Teléfono fijo de la empresa</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.telefono_empresa} type="text" name="telefono_empresa" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Teléfono movil de la empresa</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.movil_empresa} type="text" name="movil_empresa" id="" />
                        </div>
                    </div>
                    <div className="field">
                        <div className="insideField">
                            <label>Correo Electrónico de la empresa</label>
                        </div>
                        <div className="insideField">
                            <input className="inputField" onChange={handleInputChange} value={formValues.correo_empresa} type="text" name="correo_empresa" id="" />
                        </div>
                    </div>
                </div>

                <div className="groupFields" style={{ display: 'inline-block', textAlign: 'start', paddingLeft: '150px' }}>
                    <div style={{ width: '100%' }}>
                        <label>¿En qué temas considera que debería recibir capacitación el propietario o sus empleados?</label>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {/* <input className="inputField" onChange={handleInputChange} value={formValues.temas_recibir_capacitacion} type="text" name="temas_recibir_capacitacion" id="" /> */}
                        <div>
                            <input className="tema" onClick={(event) => capacitacion(event)} style={{ marginRight: '5px' }} type="checkbox" value="Mercadeo y ventas" /><label>Mercadeo y ventas</label>
                        </div>
                        <div>
                            <input className="tema" onClick={(event) => capacitacion(event)} style={{ marginRight: '5px' }} type="checkbox" value="Planeación estratégica" /><label>Planeación estratégica</label>
                        </div>
                        <div>
                            <input className="tema" onClick={(event) => capacitacion(event)} style={{ marginRight: '5px' }} type="checkbox" value="Servicio al cliente" /><label>Servicio al cliente</label>
                        </div>
                        <div>
                            <input className="tema" onClick={(event) => capacitacion(event)} style={{ marginRight: '5px' }} type="checkbox" value="Normas ISO" /><label>Normas ISO</label>
                        </div>
                        <div>
                            <input className="tema" onClick={(event) => capacitacion(event)} style={{ marginRight: '5px' }} type="checkbox" value="Bilinguismo o manejo de segundo idioma" /><label>Bilinguismo o manejo de segundo idioma</label>
                        </div>
                        <div>
                            <input className="nada" onClick={(event) => capacitacion(event)} style={{ marginRight: '5px' }} type="checkbox" value="Ninguna" /><label>Ninguna</label>
                        </div>
                        {/* <input type="checkbox" value="No sabe/No Responde" /><label>No sabe/No Responde</label> */}
                        {/* <input type="checkbox" value="Otro ¿Cuál?" /><label>Otro ¿Cuál?</label> */}
                    </div>
                </div>
                {error !== '' && <div className="groupFields" style={{ color: 'red', padding: '100px' }}>{error}</div>}
                <button onClick={validateData} className="btn btn-primary sendLogin">Enviar</button>
                <br />
                <br />
            </form>
        </div>
    )
}

export default AditionalUserInfo
