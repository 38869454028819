import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginAction, validateTokenAuth } from '../../redux/actions/authAction'
import { viewRadars } from '../../redux/actions/instrumentAction'
import { useSelector } from 'react-redux';
import './login.css'

const Login = ({ ...props }) => {

    const auth = useSelector(state => state.auth.autenticated);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState('');
    const [formValues, setformValues] = useState({
        email: '',
        password: '',
    });

    useEffect(() => {
        if (props.match.params.token) {
            if (props.match.params.token !== '') {
                validateToken(props.match.params.token);
            }
        }
    }, [])

    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            redirectDiagnosis();
        }
    }, [user])


    const validateToken = (token) => {
        try {
            dispatch(validateTokenAuth(token));
        } catch (error) {

        }
    }

    const redirectDiagnosis = () => {
        if (user.role[0].name === 'admin') {
            history.push(`/adminDashboard`);
            return;
        }
        if (user.role[0].name === 'consultor') {
            dispatch(viewRadars(true));
            history.push(`/punctualRelationshipByCompany/''/''/''`);
            return;
        }

        history.push(`/diagnosisDashboard`);
    }

    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }


    const validateData = (event) => {
        event.preventDefault();
        let internalError = '';
        if (formValues.email === '' || formValues.email === undefined) {
            internalError = 'El usuario es requerido, ';
        }
        if (formValues.password === '' || formValues.password === undefined) {
            internalError = internalError + 'La contraseña es requerida, ';
        }
        if (internalError === '') {
            setError('');
            dispatch(loginAction(formValues));
        } else {
            setError(internalError);
        }
    }



    return (
        <div className="loginMain">
            <div className="loginBox">
                <strong><h4 className="welcome">BIENVENIDO</h4></strong>
                <strong><h4 className="diagnosis">DIAGNÓSTICOS</h4></strong>
                {/* <p style={{ fontSize: '10px' }}>Ingresa con el mismo correo y contraseña</p> */}
                <form onSubmit={validateData}>
                    <div style={{ display: 'inline-flex' }}>
                        <input className="inputs" placeholder="Email" name="email" value={formValues.email} onChange={handleInputChange} type="text" />
                    </div>
                    <br />
                    <br />
                    <div style={{ display: 'inline-flex' }}>
                        <input className="inputs" placeholder="Contraseña" name="password" value={formValues.password} onChange={handleInputChange} type="password" />
                    </div>
                    <br />
                    <br />
                    {error !== '' && <div>{error}</div>}

                    <button type="submit" className="btn btn-primary sendLogin">Ingrese a la plataforma</button>
                </form>
            </div>

            <div className="footerLogin">
                <img style={{ transform: 'translate(0, 10px)' }} src={require('../../images/ui/footer.png').default} alt="" width="100%" />
            </div>
        </div>

    )
}

export default Login;
