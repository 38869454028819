import axios from 'axios';
import env from "react-dotenv";

var clienteAxios = axios;
if(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === ""){
    clienteAxios = axios.create({
        baseURL:process.env.REACT_APP_URL_API
        // baseURL:'http://127.0.0.1:8000/api/'
        // baseURL:'https://lantiasas.com/diagnosis/api/'
        // baseURL:'https://vtsas.co/diagnosis/api/'

    });
}else{
    clienteAxios = axios.create({
        baseURL:process.env.REACT_APP_URL_API
        // baseURL:'https://lantiasas.com/diagnosis/api/'
        // baseURL:'https://vtsas.co/diagnosis/api/'
        // baseURL:'http://127.0.0.1:8000/api/'
    });
}


export default clienteAxios;