import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCompanies } from '../../../redux/actions/companiesAction'
import Modal from 'react-modal';
import clienteAxios from '../../../config/axios';
import './addInstrumentModal.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#root');

export const AddInstrumentModal = ({ addInstrumentModalOpen, setaddInstrumentModalOpen, instrumentsListTocompanySelected, companySelected }) => {

    const dispatch = useDispatch();

    //states
    const [formValues, setformValues] = useState('')
    const [error, setError] = useState('');

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        dispatch(getCompanies());
        setaddInstrumentModalOpen(false);
    }

    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const sendData = async (event) => {
        event.preventDefault();
        let internalError = '';
        if (formValues.instrument_id === '' || formValues.instrument_id === undefined) {
            internalError = internalError + 'Debes seleccionar un instrumento.';
        }
        if (internalError === '') {
            formValues.company_id=companySelected
            formValues.instrument_id=Number(formValues.instrument_id)
            let response = await clienteAxios.post(`addInstrumentToCompany`, formValues);
            if (response.status === 200) {
                closeModal();
                alert('Instrumento adicionado con exito')
                setformValues('');
            }
            setError('');
        } else {
            setError(internalError);
        }
    }

    return (
        <div className="mainModal">
            <Modal
                isOpen={addInstrumentModalOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="userModalBox">
                    <div className="userModalHeader">
                        <div>
                            <strong>Agregar Instrumento</strong>
                        </div>
                        <div style={{ cursor: 'pointer', float: 'right' }} onClick={closeModal}><strong>X</strong></div>
                    </div>
                    <div className="userModalBody">
                        <form >
                            <div className="col-12 col-sm-8 col-md-11 inputs">
                                <label>Instrumento</label>
                                <select name="instrument_id" value={formValues.axes_id} onChange={handleInputChange}>
                                    <option value="">Seleccione Instrumento</option>
                                    {instrumentsListTocompanySelected.length > 0 &&
                                        instrumentsListTocompanySelected.map(instr => {
                                            return <option value={instr.id}>{instr.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {error !== '' && <div style={{ width: '300px', color: 'red' }}>{error}</div>}
                            <div onClick={sendData} className="sendButton">Crear</div>
                        </form>
                    </div>
                </div>
            </Modal >
        </div>

    )
}
