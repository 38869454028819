import { SET_COMPANIES } from '../types/companies';
import clienteAxios from '../../config/axios';


//get data
export function getCompanies() {
    return async (dispatch) => {
        try {
            let response = '';
            response = await clienteAxios.get(`companies`);
            dispatch(setCompanies(response.data));
        } catch (error) {
        }
    }
}

const setCompanies = (companies) => ({
    type: SET_COMPANIES,
    payload: companies
});