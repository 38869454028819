import React, { useEffect, useState } from 'react'
import clienteAxios from '../../config/axios'
import './trazability.css'

const Trazability = () => {

    const [trazability, setTrazability] = useState('');

    useEffect(() => {
        getTrazability(1);

    }, [])

    const getTrazability = async (page) => {
        try {
            const response = await clienteAxios.get(`getTrazability?page=${page}`);
            response.status === 200 ? setTrazability(response.data.trazability) : alert('error al traer la trazabilidad');

        } catch (error) {

        }
    }

    const next = () => {
        trazability.last_page > trazability.current_page && getTrazability(trazability.current_page + 1);
    }

    const before = () => {
        trazability.current_page > 1 && getTrazability(trazability.current_page - 1);
    }

    return (
        <div className='trazabilityMain'>
            <table>
                <thead>
                    <tr>
                        <th>Usuario</th>
                        <th>Acción</th>
                        <th>Fecha</th>
                    </tr>
                </thead>
                {trazability !== '' &&
                    <tbody>
                        {trazability.data.map(item => {
                            return (
                                <tr>
                                    <td>{`${item.name} ${item.last_name}`}</td>
                                    <td style={{width:'70%'}}>{item.action}</td>
                                    <td>{item.date.substr(0, 10)}</td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                }
            </table>
            <div>
                <samp style={{cursor:'pointer'}} onClick={() => before()}>{'<'}</samp>
                <samp className='current'>{trazability.current_page}</samp>
                <samp style={{cursor:'pointer'}} onClick={() => next()}>{'>'}</samp>
            </div>
        </div>
    )
}

export default Trazability