import React, { useState } from 'react'
import clienteAxios from '../../config/axios';
import { ExcelRenderer } from 'react-excel-renderer';
import './rutaNReport.css'

const RutaNReporte = () => {
    const sendData = async () => {
    
        try {
            let response = await clienteAxios.get(`entregable`);
            let xlsx = require('json-as-xlsx')
            let time = new Date();
            let settings = {
                fileName: 'Reporte valle del sofware ' + time.toLocaleString(), // Name of the spreadsheet
                extraLength: 10, // A bigger number means that columns will be wider
                writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
            }

            let data = [
                {
                    sheet: 'reporte',
                    columns: [
                        {label: "Tipo de documento de identidad", value:"doc_type"},
                        {label: "No. Documento de identidad", value:"identity_doc"},
                        {label: "Nombres", value:"name"},
                        {label: "Apellidos", value:"last_name"},
                        {label: "Fecha de nacimiento", value:"date_of_birth"},
                        {label: "Edad", value: "edad_ciudadano"},
                        {label: "Sexo", value:"sex"},
                        {label: "Lgtbi", value:"lgtbi"},
                        {label: "Etnia", value:"etnia"},
                        {label: "Discapacidad", value:"discapacidad"},
                        {label: "Victima", value:"victima"},
                        {label: "Poblacion campesina", value:"poblacion_campesina"},
                        {label: "Estado civil", value:"estado_civil"},
                        {label: "Nivel escolaridad", value:"nivel_escolaridad"},
                        {label: "Cabeza de hogar", value:"cabeza_hogar"},
                        {label: "Caracterización ciudadano", value:"categoria_ocupacion"},
                        {label: "Empleado de alguna organización", value:"empleado_empresa"},
                        {label: "Nombre de la empresa para la cual labora", value:"company_name_work"},
                        {label: "Dirección ciudadano", value:"adress"},
                        {label: "Estrato", value:"estrato"},
                        {label: "Barrio/Vereda ciudadano", value:"barrio_ciudadano"},
                        {label: "Comuna ciudadano", value:"comuna_ciudadano"},
                        {label: "Email ciudadano", value:"correo_ciudadano"},
                        {label: "Nit de la empresa", value:"company_nit"},
                        {label: "Nombre de la empresa/emprendimiento", value:"company_name"},
                        {label: "Naturaleza juridica", value:"naturaleza_empresa"},
                        {label: "Número empleados", value:"numero_empleados"},
                        {label: "Macro-sector", value:"macrosector"},
                        {label: "Sub-sector", value:"subsector"},
                        {label: "Productos/servicios que ofrece", value:"products_services_offered"},
                        {label: "Importa productos o servicios", value:"import_products_services"},
                        {label: "Exporta  productos o servicios", value:"exporta_productos_servicios"},
                        {label: "Empresa pertenece a red", value:"empresa_pertenece_red"},
                        {label: "Empresa asociada a UPA", value:"empresa_asociada_upa"},
                        {label: "Empresa pertenece a cluster", value:"empresa_pertenece_cluster"},
                        {label: "Promedio anual de ventas", value:"promedio_anual_ventas"},
                        {label: "Dirección principal de la empresa", value:"company_adress"},
                        {label: "Barrio empresa", value:"barrio_empresa"},
                        {label: "Telefono empresa", value:"telefono_empresa"},
                        {label: "movil_empresa", value:"movil_empresa"},
                        {label: "correo_empresa", value:"correo_empresa"},
                        {label: "Temas a recibir de capacitacion", value:"temas_recibir_capacitacion"},
                        {label: "Fecha de creación", value:"created_at"},
                    ],
                    content: response.data.report
                }
            ]
            xlsx(data, settings)
        } catch (error) {
            return 'Error interno';
        }
    }


    return (
        <div>
            <div className="importBox" >
                <hr />
                <div><strong>Reporte valle del sofware</strong></div>
                {/* <p style={{ paddingTop: '1rem' }}>importar excel</p>
                <input style={{ marginTop: '1rem', marginLeft: '0.5rem' }} onChange={(e) => fileHandler(e)} type="file" multiple /> */}
            </div>
            <div className="rutanReport" onClick={sendData}>descargar excel con toda la info</div>
            {/* {JsonData&&JSON.stringify(JsonData)} */}
        </div>
    )
}

export default RutaNReporte
