import { SET_COMPANIES } from '../types/companies';

//state
const initialState = {
    companies: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_COMPANIES:
            return {
                ...state,
                companies: action.payload,
            }

        default:
            return state;
    }
}