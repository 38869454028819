import React, { useState, useRef } from 'react';
import clienteAxios from '../../config/axios';
import './satisfactionSurveyEscuelaVt.css'

const SatisfactionSurveyEscuelaVt = () => {

    const [sent, setSent] = useState(false)
    const [formValues, setformValues] = useState({});

    const [error, setError] = useState('');
    const [cursos, setcursos] = useState([])


    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const cursosEvent = (event) => {
        let exist = cursos.find(item => item == event.target.value)
        if (exist !== undefined) {
            setcursos(cursos.filter(theme => exist !== theme))
            let capacit = '';
            cursos.filter(theme => exist !== theme).map(item => {
                capacit = capacit + ', ' + item;
            })
            formValues.cursos_realizados = capacit;
            setformValues(formValues)
            return;
        }
        setcursos([...cursos, event.target.value])
        let capacit = '';
        [...cursos, event.target.value].map(item => {
            capacit = capacit + ', ' + item;
        })

        formValues['cursos_realizados'] = capacit;
        setformValues(formValues)

    }

    const validateData = async (event) => {
        event.preventDefault();

        let internalError = '';
        if (formValues.name === '' || formValues.name === undefined) {
            internalError = 'El nombre es requerido, ';
        }
        if (formValues.lastname === '' || formValues.lastname === undefined) {
            internalError = 'El apellido es requerido, ';
        }
        if (formValues.docIdentity === '' || formValues.docIdentity === undefined) {
            internalError = 'El documento de identidad es requerido, ';
        }
        if (formValues.cursos_realizados === '' || formValues.cursos_realizados === undefined) {
            internalError = 'Los cursos realizados son requeridos, ';
        }
        if (formValues.recomendacion === '' || formValues.recomendacion === undefined) {
            internalError = '¿Qué tan probable es que recomiende a otros la Escuela VT? es requerido, ';
        }
        if (formValues.contenidos_utiles === '' || formValues.contenidos_utiles === undefined) {
            internalError = '¿Qué tan útiles considera los contenidos? es requerido, ';
        }
        if (formValues.uso_herramienta === '' || formValues.uso_herramienta === undefined) {
            internalError = '¿Qué tan fácil es el uso de la herramienta? es requerida, ';
        }
        if (formValues.mejor_curso === '' || formValues.mejor_curso === undefined) {
            internalError = '¿Cuál ha sido el mejor curso? es requerida, ';
        }
        if (formValues.curso_incorporarse === '' || formValues.curso_incorporarse === undefined) {
            internalError = '¿Cuál curso considera que puede incorporarse a Escuela VT? es requerido, ';
        }
        if (formValues.aplicacion_cursos === '' || formValues.aplicacion_cursos === undefined) {
            internalError = '¿De qué manera ha aplicado los contenidos de los cursos? es requerido, ';
        }

        if (internalError === '') {
            setError('');
            let response = await clienteAxios.post(`escueltaVTSurvey`, formValues);
            if (!response.data.hasOwnProperty('error')) {
                setSent(true)
            }
        } else {
            setError(internalError);
        }

    }

    return (
        <div className="SatisfactionSurveyEscuelaVtmain">
            <br />
            <br />
            {!sent ?
                <div>
                    <form >
                        <div className="groupFieldSurvey">
                            <h4>Encuesta de satisfacción Escuela VT</h4>

                            <div className="field">
                                <label>Nombre</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="name" value={formValues.name} onChange={handleInputChange} />
                            </div>

                            <div className="field">
                                <label>Apellido</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="lastname" value={formValues.lastname} onChange={handleInputChange} />
                            </div>

                            <div className="field">
                                <label>Identificación</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="docIdentity" value={formValues.docIdentity} onChange={handleInputChange} />
                            </div>
                            <div className="field">
                                <label>Cursos realizados</label>
                            </div>
                            <div className="field">
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <input className="tema" onClick={(event) => cursosEvent(event)} style={{ marginRight: '5px' }} type="checkbox" value="Vigilancia Tecnológica" /><label>Vigilancia Tecnológica</label>
                                    <input className="tema" onClick={(event) => cursosEvent(event)} style={{ marginRight: '5px' }} type="checkbox" value="Propiedad Intelectual" /><label>Propiedad Intelectual</label>
                                    <input className="tema" onClick={(event) => cursosEvent(event)} style={{ marginRight: '5px' }} type="checkbox" value="Liderazgo" /><label>Liderazgo</label>
                                    <input className="tema" onClick={(event) => cursosEvent(event)} style={{ marginRight: '5px' }} type="checkbox" value="Design Thinking" /><label>Design Thinking</label>
                                </div>
                            </div>
                            <div className="field">
                                <label>¿Qué tan probable es que recomiende a otros la Escuela VT?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="recomendacion" value={formValues.recomendacion} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                            <div className="field">
                                <label>¿Qué tan útiles considera los contenidos?</label>
                            </div>
                            <div className="field">
                                <select className="inputField" name="contenidos_utiles" value={formValues.contenidos_utiles} onChange={handleInputChange}>
                                    <option value="">Seleccione calificación</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            <div className="field">
                                <label>¿Qué tan fácil es el uso de la herramienta?
                                </label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="uso_herramienta" value={formValues.uso_herramienta} onChange={handleInputChange} />
                            </div>
                            <div className="field">
                                <label>¿Cuál ha sido el mejor curso?
                                </label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="mejor_curso" value={formValues.mejor_curso} onChange={handleInputChange} />
                            </div>
                            <div className="field">
                                <label>¿Cuál curso considera que puede incorporarse a Escuela VT?
                                </label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="curso_incorporarse" value={formValues.curso_incorporarse} onChange={handleInputChange} />
                            </div>
                            <div className="field">
                                <label>¿De qué manera ha aplicado los contenidos de los cursos?
                                </label>
                            </div>
                            <div className="field">
                                <textarea style={{ border: '1px solid gray' }} name="aplicacion_cursos" rows="7" cols="67" value={formValues.aplicacion_cursos} onChange={handleInputChange}></textarea>
                            </div>
                            {error !== '' && <div className="groupFieldSurvey" style={{ color: 'red', padding: '100px' }}>{error}</div>}
                            <br />
                            <br />
                            <button onClick={validateData} className="btn btn-primary sendLogin">Enviar</button>
                            <br />
                            <br />
                        </div>
                    </form>
                </div>
                :
                <div className="sent">
                    Respuestas enviadas!
                </div>
            }


        </div>
    )
}

export default SatisfactionSurveyEscuelaVt
