import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../redux/actions/authAction';
import { useHistory } from 'react-router-dom';
import clienteAxios from '../../../config/axios';
import { RadarModal } from '../../modals/radar/RadarModal';
import { QuestionModal } from '../../modals/question/QuestionModal';
import { AxisModal } from '../../modals/axis/AxisModal';
import "./InstrumentManagment.css"

const InstrumentManagment = ({ ...props }) => {
    const history = useHistory();
    const [instrument, setInstrument] = useState("")
    const user = useSelector(state => state.auth.user);
    const [editionQuestionMode, seteditionQuestionMode] = useState({ flag: false, idQuestion: '' })

    //modals
    const [axisModalopen, setAxisModalopen] = useState(false)
    const [radarModalopen, setRadarModalopen] = useState(false)
    const [questionModalopen, setQuestionModalopen] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        getInstrumentsWithAll();
    }, [])

    const getInstrumentsWithAll = async () => {

        let response = await clienteAxios.get(`instrumentwithAll/${props.computedMatch.params.id}`);
        setInstrument(response.data.axis);
    }

    const logout = () => {
        dispatch(logOut(user.user.id));
        history.push('/');
    }

    const openRadarModal = () => {
        setRadarModalopen(true);
    }
    const openAxisModal = () => {
        setAxisModalopen(true);
    }
    const openQuestionModal = () => {
        setQuestionModalopen(true);
    }

    const editQuestion = (question) => {
        seteditionQuestionMode({ flag: true, idQuestion: question.id })
    }

    const handleInputChange = (instrumentIndex, componentIndex, questionIndex, event) => {
        let tmp = [...instrument]
        tmp[instrumentIndex].component[componentIndex].question[questionIndex].question = event.target.value;
        setInstrument(tmp);
    }

    const saveQuestion = async (instrumentIndex, componentIndex, questionIndex) => {
        try {
            let response = await clienteAxios.post(`createQuestion`, instrument[instrumentIndex].component[componentIndex].question[questionIndex]);
            if (!response.data.hasOwnProperty('error')) {
                seteditionQuestionMode({ flag: false, idQuestion: '' })
                alert('Pregunta editada')
            }
        } catch (error) {

        }

    }

    const deleteQuestion = async(instrumentIndex, componentIndex, questionIndex, id) => {
        let tmp = [...instrument]
        tmp[instrumentIndex].component[componentIndex].question.splice(questionIndex, 1);
        setInstrument(tmp);
        try {
            let response = await clienteAxios.delete(`deleteQuestion/${id}`);
            
        } catch (error) {
            
        }
    }

    return (
        <div>
            <div style={{ position: 'fixed', top: '0', alignItems: 'start' }} className="navbar d-flex  justify-content-end">
                <div onClick={logout} className="logOut">Salir</div>
            </div>

            <div className="createMenu">
                <div onClick={openRadarModal} className="createButton">Crear Radar</div>
                <div onClick={openAxisModal} className="createButton">Crear Eje</div>
                <div onClick={openQuestionModal} className="createButton">Crear Pregunta</div>
            </div>
            <div className="instrumentTable">
                <div className="row">
                    <div className="headerInstrument">
                        <div className="col-4 col-md-4 col-lg-4"><strong>Radar</strong></div>
                        <div className="col-4 col-md-4 col-lg-4"><strong>Eje</strong></div>
                        <div className="col-4 col-md-4 col-lg-4"><strong>Preguntas</strong></div>
                    </div>
                </div>

                <div className="instrumentTableBody">
                    {instrument !== "" &&
                        instrument.map((item, instrumentIndex) => {
                            return (
                                <div className="row item">
                                    <div className="col-4 col-md-4 col-lg-4">
                                        {item.name}
                                    </div>
                                    <div className="col-8 col-md-8 col-lg-8">
                                        {item.component.map((comp, componentIndex) => {
                                            return (
                                                <div className="row itemComponent">
                                                    <div className="col-4 col-md-4 col-lg-4">
                                                        {
                                                            comp.name
                                                        }
                                                    </div>
                                                    <div className="col-8 col-md-8 col-lg-8">
                                                        {
                                                            comp.question.map((quest, questionIndex) => {
                                                                return (
                                                                    <div className="itemQuestion">
                                                                        {(editionQuestionMode.flag && editionQuestionMode.idQuestion == quest.id) ?
                                                                            <div>
                                                                                <textarea onChange={(event) => handleInputChange(instrumentIndex, componentIndex, questionIndex, event)} style={{ marginTop: '20px' }} value={quest.question} name="question" id="" cols="60" rows="3"></textarea>
                                                                                <div onClick={() => saveQuestion(instrumentIndex, componentIndex, questionIndex)} className="editBoton">Guardar cambios</div>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <div>{quest.question}</div>
                                                                                <div style={{display:'inline-flex'}}>
                                                                                    <div style={{marginRight:'10px'}} onClick={() => editQuestion(quest)} className="editBoton">Editar</div>
                                                                                    <div onClick={() => deleteQuestion(instrumentIndex, componentIndex, questionIndex, quest.id)} className="deleteBoton">Eliminar</div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>


            </div>
            <RadarModal instrument_id={props.computedMatch.params.id} getInstrumentsWithAll={getInstrumentsWithAll} radarModalopen={radarModalopen} setRadarModalopen={setRadarModalopen} />
            <QuestionModal getInstrumentsWithAll={getInstrumentsWithAll} instrument={instrument} questionModalopen={questionModalopen} setQuestionModalopen={setQuestionModalopen} />
            <AxisModal getInstrumentsWithAll={getInstrumentsWithAll} instrument={instrument} axisModalopen={axisModalopen} setAxisModalopen={setAxisModalopen} />
        </div>
    )
}

export default InstrumentManagment
