import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import clienteAxios from '../../config/axios';
import './formColProd.css'

const FormColProd = () => {

    const auth = useSelector(state => state.auth.user);
    const [formvalues, setformvalues] = useState('')
    const [error, setError] = useState('')

    const handleInputChange = (event) => {
        setformvalues({
            ...formvalues,
            [event.target.name]: event.target.value
        })
    }

    const sendForm = async(event) => {
        event.preventDefault();

        let internalError = '';
        if (formvalues.subjects === '' || formvalues.subjects === undefined) {
            internalError = 'Los Objetivos son requeridos, ';
        }
        if (internalError === '') {
            setError('');
            formvalues.user_id = auth.user.id
            let response = await clienteAxios.post(`saveAnswers`, formvalues);
            if (!response.data.hasOwnProperty('error')) {
                alert('Respuestas enviadas')
            }

        } else {
            setError(internalError);
        }
    }

    return (
        <div className="formColProdMain">
            <form action="">
                <div className="">
                    <label htmlFor="">
                        ¿Cuáles son los objetivos de generación de valor que persigue la empresa en las posibles alianzas que realizaría a través del proyecto Encadenamientos Naranja?
                        Respuesta abierta, mínimo dos párrafos, máximo 4
                    </label>
                    <textarea onChange={handleInputChange} rows="10" cols="50" name="subjects" className="inputFieldColProd" type="text" />
                </div>
                <div onClick={sendForm} className="btn btn-primary sendLogin">Enviar respuesta</div>
            </form>
        </div>
    )
}

export default FormColProd
