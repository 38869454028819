import React, { useState } from 'react';
import Modal from 'react-modal';
import clienteAxios from '../../../config/axios';
import './radarModal.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#root');

export const RadarModal = ({ radarModalopen, setRadarModalopen, getInstrumentsWithAll, instrument_id }) => {

    //states
    const [formValues, setformValues] = useState('')
    const [error, setError] = useState('');

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setRadarModalopen(false);
        getInstrumentsWithAll();
    }

    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const sendData = async(event) => {
        event.preventDefault();
        let internalError = '';
        if (formValues.name === '' || formValues.name === undefined) {
            internalError = internalError  + 'El nombre es requerido, ';
        }
        if (internalError === '') {
            formValues.instrument_id = instrument_id;
            let response = await clienteAxios.post(`createAxis`, formValues);
            if(response.status === 200){
                closeModal();
                alert('Radar creado con exito')
            }
            setError('');
        } else {
            setError(internalError);
        }
    }

    return (
        <div className="mainModal">
            <Modal
                isOpen={radarModalopen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="userModalBox">
                    <div className="userModalHeader">
                        <div>
                            <strong>Crear Radar</strong>
                        </div>
                        <div style={{ cursor: 'pointer', float:'right' }} onClick={closeModal}><strong>X</strong></div>
                    </div>
                    <div className="userModalBody">
                        <form >
                            <div className="userFormItem">
                                <label>Nombre</label>
                                <input onChange={handleInputChange} type="text" value={formValues.name} name="name" />
                            </div>
                            {error !== '' && <div style={{width:'300px', color:'red'}}>{error}</div>}
                            <div onClick={sendData} className="sendButton">Crear</div>
                        </form>
                    </div>
                </div>
            </Modal >
        </div>

    )
}
