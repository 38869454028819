import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logOut } from '../../redux/actions/authAction';
import { setCurrentScores } from '../../redux/actions/scoreAction';
import PunctualRelationship from '../../components/instrument/punctualRelationship/PunctualRelationship'
import TestAnalysis from '../instrument/testAnalysis/TestAnalysis';
import { cleanInstrument } from '../../redux/actions/instrumentAction';
import clienteAxios from '../../config/axios';
import FormColProd from '../formColProd/FormColProd';
import './poll.css'

const Poll = (props) => {

    //redux
    const auth = useSelector(state => state.auth.user);
    const questions = useSelector(state => state.instruments.instrument);
    const instrument = useSelector(state => state.instruments.instrument);
    const user = useSelector(state => state.auth.user);
    const loading = useRef(true)

    //states
    const [componentScore, setComponentScore] = useState([]);
    const [questionsPagination, setQuestionsPagination] = useState([]);
    const history = useHistory();
    const [answers, setAnswers] = useState([])
    const pagination = useRef(0)
    const dispatch = useDispatch();

    useEffect(() => {
        if (questions !== undefined && questions.length > 0) {
            loading.current = false;
            setQuestionsPagination(questions.slice(pagination.current, 8))
            getAnswers(auth.user.id)
        }
    }, [questions])


    //obtener las respuestas guardadas
    const getAnswers = async (userId) => {
        let answ = await clienteAxios.get(`answers/${userId}`);
        setAnswers(answ.data.answers)
    }

    //almacenar las respuestas nuevas en un state
    const score = (value, item) => {
        let answerExist = answers.find(answ => answ.question_id == item.id);
        if (answerExist !== undefined) {
            let newAnswers = answers.filter(answ => answ !== answerExist)
            answerExist.score = value
            newAnswers.push(answerExist)
        } else {
            answers.push({ question_id: item.id, score: value })
        }

        let exist = componentScore.find(quest => quest.component === item.component_id)
        if (exist !== undefined) {//existe
            let newScore = componentScore.filter(quest => {
                return quest.id !== item.id;
            })
            newScore.push({ 'id': item.id, 'component': item.component_id, 'scoreQuestion': value, 'axis': item.axes_id, 'userId': auth.user.id });
            setComponentScore(newScore);
        } else {//no existe
            let newScore = componentScore
            newScore.push({ 'id': item.id, 'component': item.component_id, 'scoreQuestion': value, 'axis': item.axes_id, 'userId': auth.user.id })
            setComponentScore(newScore)
        }


    }

    //calcular media y enviar respuestas
    const send = async () => {

        let components = [];
        let res = await clienteAxios.post(`answers`, componentScore);
        componentScore.forEach(element => {
            components.push(element.component)
        });
        let uniq = [...new Set(components)];


        let arrayScores = [];

        uniq.forEach((item, index) => {
            let obj = {}
            let nameComponent = 'component' + item.toString()
            obj[nameComponent] = 0;
            obj.totalQuestion = 0;
            obj.idComponent = item;
            obj.axis = 0;
            arrayScores.push(obj);
        });


        componentScore.forEach(element => {
            arrayScores.map(i => {
                if (element.component === i.idComponent) {
                    i[Object.keys(i)[0]] = i[Object.keys(i)[0]] + parseInt(element.scoreQuestion)
                    i.totalQuestion = i.totalQuestion + 1;
                    i.axis = element.axis
                }
            });
        })

        arrayScores.map(item => {
            item.media = item[Object.keys(item)[0]] / item[Object.keys(item)[1]]
            item.companyId = auth.user.company_id
            item.userId = auth.user.id
        })
        try {
            let response = '';
            response = await clienteAxios.post(`insertScoreUser`, arrayScores);

            if (response.status === 200) {
                alert('has enviado las respuestas correctamente')
            }
        } catch (error) {
            alert('las respuestas no pudieron ser enviadas, intenta mas tarde')
        }
    }

    //paginacion adelante
    const next = () => {
        if (questionsPagination.length === 0 && pagination.current > 0) {
            return;
        }
        pagination.current++;
        console.table('pagination.current', pagination.current, pagination.current * 8, questions, questions.slice(pagination.current * 8, (pagination.current * 8) + 8));
        setQuestionsPagination(questions.slice(pagination.current * 8, (pagination.current * 8) + 8))
    }

    //paginacion atras
    const before = () => {
        if (pagination.current < 0) {
            return;
        }
        if (pagination.current === 0) {
            setQuestionsPagination(questions.slice(0, 8))
        }

        if (pagination.current > 0) {
            pagination.current--;
        }

        setQuestionsPagination(questions.slice(pagination.current * 8, (pagination.current * 8) + 8))
    }

    //logica para hacer que la mira en la 
    //calificacion se desplace
    const multiplicador = (value) => {
        let newValue = ''
        if (value == 1) {
            newValue = (value * 10).toString() + 'px';
        }
        if (value == 2) {
            newValue = (value * 60).toString() + 'px';
        }
        if (value == 3) {
            newValue = (value * 77).toString() + 'px';
        }
        if (value == 4) {
            newValue = (value * 85).toString() + 'px';
        }
        if (value == 5) {
            newValue = (value * 90).toString() + 'px';
        }

        return newValue;
    }

    // del sistema
    const logout = () => {
        dispatch(logOut(user.user.id));
        history.push('/');
    }

    const goMenu = () => {
        dispatch(cleanInstrument());
        history.push('/diagnosisDashboard');
    }

    return (
        <div className="pollComponent">
            <div onClick={goMenu} className="goMenu">volver al menu de diagnosticos</div>
            <div className="header">
                <strong>DIAGNÓSTICOS</strong>
            </div>
            <div className="logout" style={{ transform: 'translate(0px, -40px)' }} onClick={logout}>Salir</div>

            {questionsPagination.length > 0 &&
                <div className="text" dangerouslySetInnerHTML={{ __html: instrument[0].welcome_text }} />
            }
            {(instrument[0] != undefined &&
                instrument[0].instrument_id == 11) &&
                <FormColProd />
            }
            <div className="surveyBox">
                <div className="pagination">
                    <div onClick={before} style={{ marginRight: '30px', cursor: 'pointer' }}>
                        {<img src={require('../../images/icons/leftRow.png').default} alt="Girl in a jacket" width="20" height="25"></img>}
                    </div>
                </div>
                <div className="pollComponentBody">
                    {
                        loading.current && <div>Cargando diagnostico...</div>
                    }
                    {questionsPagination.length > 0 ?
                        questionsPagination.map((item, index) => {

                            let exist = answers.find(i => i.question_id == item.id)

                            //let mira = exist === undefined ? 1 : exist.score
                            let mira = 0
                            if (exist === undefined) {
                                // mira = 1;
                                // score(1, item)
                            } else {
                                mira = exist.score;
                            }

                            let options = []
                            if (item.question.includes('*&%#)&')) {
                                options = item.question.split('*&%#)&')
                            }

                            return (<div className="questions">
                                <div className="row item">
                                    <div className="col-6 col-md-6 col-lg-6">
                                        <div>
                                            {options.length !== 0 ?
                                                <div>
                                                    <div>
                                                        <div>{((8 * pagination.current) + (index + 1)) + '.'}{options[0]}</div>
                                                    </div>
                                                    {
                                                        options.map((item, index) => {
                                                            return (
                                                                <div style={{ width: '100%' }}>
                                                                    {index > 0 && item}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                :
                                                <div>{((8 * pagination.current) + (index + 1)) + '.'}{item.question}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-6">
                                        <img style={{ transform: `translate(${exist !== undefined ? multiplicador(mira) : '-10px'}, 3px)`, visibility: `${exist !== undefined ? 'visible' : 'hidden'}` }} className="mira" src={require('../../images/ui/medidor.png').default} alt="Girl in a jacket" width="17" height="20" />
                                        <div className="scoreImage">
                                            <div onClick={() => score(1, item)} className="scoreRadio">1</div>
                                            <div onClick={() => score(2, item)} className="scoreRadio">2</div>
                                            <div onClick={() => score(3, item)} className="scoreRadio">3</div>
                                            <div onClick={() => score(4, item)} className="scoreRadio">4</div>
                                            <div onClick={() => score(5, item)} className="scoreRadio">5</div>
                                        </div>
                                        <img style={{ transform: `translate(${exist !== undefined ? multiplicador(mira) : '-10px'}, -6px)`, visibility: `${exist !== undefined ? 'visible' : 'hidden'}` }} className="mira2" src={require('../../images/ui/medidor2.png').default} alt="Girl in a jacket" width="17" height="20" />

                                        {/* <div className="row acuerdo">
                                                <div style={{ width: '70px' }}>Fuertemente en
                                                    desacuerdo</div>
                                                <div style={{ width: '70px' }}>Si, totalmente
                                                    de acuerdo</div>
                                            </div> */}

                                    </div>
                                </div>
                            </div>);
                        }) :

                        <div style={{ paddingTop: '100px' }}>
                            {pagination.current > 0 &&
                                <div>
                                    <div className="text" dangerouslySetInnerHTML={{ __html: instrument[0].farewell_text }} />
                                    {instrument[0].type == "1" ?
                                        <div>
                                            <PunctualRelationship />
                                        </div> :
                                        <div>
                                            <TestAnalysis />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }

                </div>
                <div className="pagination">
                    <div onClick={next} style={{ cursor: 'pointer' }}>
                        {<img src={require('../../images/icons/rightRow.png').default} alt="Girl in a jacket" width="20" height="25"></img>}
                    </div>
                </div>
            </div>


            <div className="sendButton" onClick={send}>Enviar y guardar</div>
            <div className="footerCarrousel">
                <img style={{ transform: 'translate(0, 10px)' }} src={require('../../images/ui/footer.png').default} alt="" width="100%" />
            </div>

        </div>
    )
}

export default Poll
