import {
    SET_INSTRUMENTS, SET_INSTRUMENT, SET_AXES, SET_COMPONENTS, SET_RADARS
} from '../types/instrument';
import axios from 'axios';
import clienteAxios from '../../config/axios';


//get data
export function getInstruments(companyId) {
    return async (dispatch) => {
        try {
            let response = '';
            //response = await axios.get(`http://lantiasas.com/diagnosis/api/instruments/${companyId}`);
            response = await clienteAxios.get(`instruments/${companyId}`);
            let exist = response.data.instruments[0].instruments.find(item => item.name == 'Formalización empresarial y laboral');
            if(exist !== undefined){
                let dataFilter = response.data.instruments[0].instruments.filter(item => item.name !== 'Formalización empresarial y laboral');
                dispatch(setInstruments([exist, ...dataFilter]));  

            }else{
               dispatch(setInstruments(response.data.instruments[0].instruments));  
            }
           
        } catch (error) {
        }
    }
}

//get data
export function getAllInstruments() {
    return async (dispatch) => {
        try {
            let response = '';
            response = await clienteAxios.get(`allInstruments`);
            dispatch(setInstruments(response.data));
        } catch (error) {
        }
    }
}

//obtiene los ejes del radar seleccionado
export function getAxes(instrumentId) {
    return async (dispatch) => {
        try {
            let response = '';
            response = await clienteAxios.get(`axesByInstrument/${instrumentId}`);
            //response = await axios.get(`http://lantiasas.com/diagnosis/api/axesByInstrument/${instrumentId}`);
            dispatch(setAxes(response.data));
        } catch (error) {
        }
    }
}

//obtiene los componentes del eje seleccionado
export function getComponents(axesId) {
    return async (dispatch) => {
        try {

            let response = '';
            response = await clienteAxios.get(`componentByAxes/${axesId}`);
            //response = await axios.get(`http://lantiasas.com/diagnosis/api/componentByAxes/${axesId}`);

            dispatch(setComponents(response.data));
        } catch (error) {
        }
    }
}

//selecciona el instrumento
export function selectInstrument(instrument) {
    return async (dispatch) => {
        try {
            let response = '';
            response = await clienteAxios.get(`instrument/${instrument.id}`);
            dispatch(setInstrument(response.data.instrument));
        } catch (error) {
        }
    }
}

export function cleanInstrument(){
    return async (dispatch) => {
        try {
            dispatch(setInstrument([]));
        } catch (error) {
        }
    }
}

export function viewRadars(flag){
    return async (dispatch) => {
        try {
            dispatch(setRadars(flag));
        } catch (error) {
        }
    }
}

const setRadars = (flag) => ({
    type: SET_RADARS,
    payload: flag
});


const setInstruments = (instruments) => ({
    type: SET_INSTRUMENTS,
    payload: instruments
});

const setInstrument = (instruments) => ({
    type: SET_INSTRUMENT,
    payload: instruments
});

const setAxes = (axes) => ({
    type: SET_AXES,
    payload: axes
});

const setComponents = (components) => ({
    type: SET_COMPONENTS,
    payload: components
});