import { combineReducers } from 'redux';
import instrumentReducer from './instrumentReducer';
import authReducer from './authReducer';
import scoreReducer from './scoreReducer';
import userReducer from './userReducer';
import companiesReducer from  './companiesReducer';

export default combineReducers({
    instruments: instrumentReducer,
    auth: authReducer,
    score: scoreReducer,
    users: userReducer,
    companies: companiesReducer
});