import React, { useEffect, useState, useRef } from 'react'
import { Radar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { getScores, getScoresByUser } from '../../../redux/actions/scoreAction'
import clienteAxios from '../../../config/axios';
import './punctualRelationshipAdmin.css'


const emptyChart = {
    labels: [],
    datasets: [
        {
            label: 'Diangnostico',
            data: [],
            backgroundColor: 'hsla(0, 0%, 0%, 0)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
        },
    ],
};

const options = {

    scales: {
        r: {
            angleLines: {
                display: false
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    }
};


const PunctualRelationshipAdmin = () => {

    //redux
    const dispatch = useDispatch();
    const score = useSelector(state => state.score.scores);
    const company = useSelector(state => state.auth.user.user.company_id);
    const role = useSelector(state => state.auth.user.role[0].name);
    const axes = useSelector(state => state.instruments.axes);
    const graphRadar = useRef(true);
    const [companies, setcompanies] = useState('');
    const [companySelected, setCompanySelected] = useState('');

    //state
    const [dataChart, setDataChart] = useState([]);
    const [radar, setRadar] = useState('');
    const [users, setUsers] = useState('');
    const [userSelected, setUserSelected] = useState('')

    useEffect(() => {
        if (role === 'admin') {
            companiesList()
        }
    }, [role])

    useEffect(() => {
        if (Object.keys(score).length !== 0) {
            if (score.score.length > 0) {
                graphScore();
            } else {
                graphRadar.current = false;
                setDataChart(emptyChart);
                //setRadar('');
            }
        }
    }, [score])

    const companiesList = async () => {
        try {
            let companies = await clienteAxios.get(`companies`);
            setcompanies(companies.data);
        } catch (error) {

        }
    }

    /*saca el calculo del radar seleccionado
    de dicho instrumento de manera global
    teniendo en cuenta la calificacion de todos los usuarios*/
    const graphScore = () => {
        let eje = score.componentsNames.find(item => item.id == score.score[0].component_id)
        let ejeId = ''
        if(eje !== undefined){
            ejeId = eje.axes_id
        }

        let ejeName = axes.find(item => item.id == ejeId)

        let components = []
        score.score.map(item => {
            components.push(item.component_id)
        })

        let uniq = [...new Set(components)];

        let arrayScores = [];
        uniq.forEach((item, index) => {
            let obj = {}
            let nameComponent = 'component' + item.toString()
            obj[nameComponent] = 0;
            obj.idComponent = item.toString();
            obj.accumulatedScores = 0;
            obj.numberOfScores = 0
            arrayScores.push(obj);
        });

        score.score.map(item => {
            arrayScores.map(i => {
                if (item.component_id == i.idComponent) {
                    i[Object.keys(i)[2]] = i[Object.keys(i)[2]] + parseInt(item.media)
                    i.numberOfScores = i.numberOfScores + 1;
                }
            })
        })

        arrayScores.map(item => {
            item.finalMedia = item.accumulatedScores / item.numberOfScores
            return item;
        })
        let labels = [];
        let data = []
        arrayScores.map(item => {
            data.push(item.finalMedia)

            labels.push(item.idComponent)
        })

        labels = labels.map((item, index) => {
            let newData = score.componentsNames.find(i => i.id == parseInt(item)).name
            return newData;
        })
        let graph = {
            labels: labels,
            datasets: [
                {
                    label: 'Media',
                    data: data,
                    backgroundColor: 'hsla(0, 0%, 0%, 0)',
                    borderColor: '#016594',
                    borderWidth: 1,
                },
            ],
        }
        let dataAndAxisName = {graph:graph, axis: ejeName.name}
        graphRadar.current = true;
        setDataChart([...dataChart, dataAndAxisName])
    }

    //selecciona el radar correspondiente y lo 
    //envia al back para consultar las medias globales por componente
    // const handleSelectChange = () => {
    //     axes.map((item) => {
    //         dispatch(getScores(item.id, role === 'admin' ? companySelected : company))
    //     });
    // }

    const changeCompany = async (event) => {
        setDataChart([])
        setCompanySelected(event.target.value)
        if (axes !== undefined && axes.length > 0) {
            axes.map((item) => {
                dispatch(getScores(item.id, role === 'admin' ? event.target.value : company))
            });
        }

        try {
            let users = await clienteAxios.get(`getUsersByCompany/${event.target.value}`);
            setUsers(users.data);
        } catch (error) {

        }
    }

    const changeUser = (event) => {
        setDataChart([])
        setUserSelected(event.target.value)
        if (axes !== undefined && axes.length > 0) {
            axes.map((item) => {
                dispatch(getScoresByUser(item.id, event.target.value))
            });
        }
    }

    return (
        <div className="PunctualRelationshipComponent">
            {role === 'admin' &&
                <div class="categoriesBox">
                    <select value={company.id} onChange={changeCompany}>
                        <option value={company.id}>{company.name}</option>
                        <hr />
                        {companies !== '' &&
                            companies.map(item => {
                                return <option value={item.id}>{item.name}</option>;
                            })
                        }
                    </select>
                </div>
            }
            <br />
            {users !== '' &&
                <div class="categoriesBox">
                    <div>Ver radares por usuario</div>
                    <select value={userSelected.id} onChange={changeUser}>
                        <option value={userSelected.id}>{userSelected.name}</option>
                        <hr />
                        {users !== '' &&
                            users.map(item => {
                                return <option value={item.id}>{item.name}</option>;
                            })
                        }
                    </select>
                </div>
            }
            <br />
            <div style={{ marginLeft: '10%' }} className="row container">
                {dataChart.length > 0 ?
                    dataChart.map((item, index) => {
                        return (
                            <div className="col-6 col-md-6 col-lg-6 col-lx-6">
                                <div className="radarBox">
                                    <h4>{item.axis}</h4>
                                    <Radar data={item.graph} options={options} />
                                </div>
                            </div>
                        );
                    })
                    :
                    radar === '' ?
                        <div className="emptyData">Selecciona la Empresa.</div>
                        :
                        <div className="emptyData">Todavía no tenemos datos para graficar este radar.</div>
                }
            </div>

        </div>
    )
}

export default PunctualRelationshipAdmin
