import React, { useEffect, useState, useRef } from 'react'
import { Radar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { getScores } from '../../../redux/actions/scoreAction'
import clienteAxios from '../../../config/axios';
import './PunctualRelationship.css'


const emptyChart = {
    labels: [],
    datasets: [
        {
            label: 'Diangnostico',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
        },
    ],
};

const options = {

    scales: {
        r: {
            angleLines: {
                display: false
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    }
};


const PunctualRelationship = () => {

    //redux
    const dispatch = useDispatch();
    const score = useSelector(state => state.score.scores);
    const company = useSelector(state => state.auth.user.user.company_id);
    const role = useSelector(state => state.auth.user.role[0].name);
    const axes = useSelector(state => state.instruments.axes);
    const componentsOfAxis = useSelector(state => state.score.componentsNames);
    const graphRadar = useRef(true)
    const [companies, setcompanies] = useState('')
    const [companySelected, setCompanySelected] = useState('')

    //state
    const [dataChart, setDataChart] = useState(emptyChart)
    const [radar, setRadar] = useState('')

    useEffect(() => {
        if (role === 'admin') {

            companiesList()
        }
    }, [role])

    useEffect(() => {
        if (Object.keys(score).length !== 0) {
            if (score.score.length > 0) {
                graphScore();
            } else {
                graphRadar.current = false;
                setDataChart(emptyChart);
                //setRadar('');
            }

        }
    }, [score])

    const companiesList = async () => {
        try {
            let companies = await clienteAxios.get(`companies`);
            setcompanies(companies.data);
        } catch (error) {

        }

    }

    /*saca el calculo del radar seleccionado
    de dicho instrumento de manera global
    teniendo en cuenta la calificacion de todos los usuarios*/
    const graphScore = () => {
        let components = []
        score.score.map(item => {
            components.push(item.component_id)
        })

        let uniq = [...new Set(components)];

        let arrayScores = [];
        uniq.forEach((item, index) => {
            let obj = {}
            let nameComponent = 'component' + item.toString()
            obj[nameComponent] = 0;
            obj.idComponent = item.toString();
            obj.accumulatedScores = 0;
            obj.numberOfScores = 0
            arrayScores.push(obj);
        });

        score.score.map(item => {
            arrayScores.map(i => {
                if (item.component_id == i.idComponent) {
                    i[Object.keys(i)[2]] = i[Object.keys(i)[2]] + parseInt(item.media)
                    i.numberOfScores = i.numberOfScores + 1;
                }
            })
        })

        arrayScores.map(item => {
            item.finalMedia = item.accumulatedScores / item.numberOfScores
            return item;
        })
        let labels = [];
        let data = []
        arrayScores.map(item => {
            data.push(item.finalMedia)

            labels.push(item.idComponent)
        })

        labels = labels.map((item, index) => {
            let newData = score.componentsNames.find(i => i.id == parseInt(item)).name
            return newData;
        })
        let graph = {
            labels: labels,
            datasets: [
                {
                    label: 'Media',
                    data: data,
                    backgroundColor: '#9CE7FE',
                    borderColor: '#016594',
                    borderWidth: 1,
                },
            ],
        }
        graphRadar.current = true;
        setDataChart(graph)
    }

    //selecciona el radar correspondiente y lo 
    //envia al back para consultar las medias globales por componente
    const handleSelectChange = (event) => {
        let axisName = axes.find(axis => axis.id == event.target.value)
        setRadar(axisName)
        dispatch(getScores(event.target.value, role === 'admin' ? companySelected : company))
    }

    const changeCompany = (event) => {
        setCompanySelected(event.target.value)
    }

    return (
        <div className="PunctualRelationshipComponent">
            {role === 'admin' &&
                <div class="categoriesBox">
                    <select value={company.id} onChange={changeCompany}>
                        <option value={company.id}>{company.name}</option>
                        <hr />
                        {companies !== '' &&
                            companies.map(item => {
                                return <option value={item.id}>{item.name}</option>;
                            })
                        }
                    </select>
                </div>
            }
            <br />
            {((role === 'admin' && companySelected !== '') || (role !== 'admin')) &&
                <div class="categoriesBox">
                    <select value={radar.id} onChange={handleSelectChange}>
                        <option value={radar.id}>{radar.name}</option>
                        <hr />
                        {axes !== undefined &&
                            axes.map(item => {
                                return <option value={item.id}>{item.name}</option>;
                            })
                        }
                    </select>
                </div>
            }

            <div className="radarChart">
                {(dataChart.labels.length > 0 && radar !== '' && graphRadar.current) ?
                    <Radar data={dataChart} options={options} /> :
                    radar === '' ?
                        <div className="emptyData">Selecciona el radar.</div>
                        :
                        <div className="emptyData">Todavía no tenemos datos para graficar este radar.</div>
                }
            </div>
        </div>
    )
}

export default PunctualRelationship
