import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllInstruments, viewRadars } from '../../redux/actions/instrumentAction'
import { getUsers } from '../../redux/actions/userAction'
import { UserModal } from '../modals/user/UserModal'
import { InstrumentModal } from '../modals/instrument/InstrumentModal'
import { ComapanyModal } from '../modals/company/CompanyModal';
import { AddInstrumentModal } from '../modals/addInstrument/AddInstrumentModal';
import { useHistory } from 'react-router-dom';
import { getCompanies } from '../../redux/actions/companiesAction'
import { selectInstrument, getAxes } from '../../redux/actions/instrumentAction'
import { logOut } from '../../redux/actions/authAction';
import './adminDashboard.css'
import { Radar } from 'react-chartjs-2';

const AdminDashboard = () => {

    const instruments = useSelector(state => state.instruments.instrumentsList);
    const users = useSelector(state => state.users.users);
    const user = useSelector(state => state.auth.user);
    const companies = useSelector(state => state.companies.companies);
    const history = useHistory();
    const [instrumentList, setInstrumentList] = useState(false)
    const [instrumentsListTocompanySelected, setInstrumentsListTocompanySelected] = useState('')

    //modals
    const [userModalopen, setUserModalopen] = useState(false)
    const [instrumentModalopen, setInstrumentModalopen] = useState(false)
    const [companyModalOpen, setcompanyModalOpen] = useState(false)
    const [addInstrumentModalOpen, setaddInstrumentModalOpen] = useState(false)

    const [userToEdit, setUserToEdit] = useState('')
    const [companySelected, setcompanySelected] = useState('')

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllInstruments());
        dispatch(getUsers());
        dispatch(getCompanies());
    }, [])

    const openModalUser = (item) => {
        setUserToEdit(item)
        setUserModalopen(true);
    }

    const openModalInstrument = () => {

        setInstrumentModalopen(true);
    }

    const openModalCompany = () => {

        setcompanyModalOpen(true);
    }

    const openAddInstrumentModalOpen = (item) => {
        setcompanySelected(item.id)
        var list = []
        instruments.map(instrument => {

            let exist = item.instruments.find(instr => {
                return instr.id == instrument.id
            });

            if (exist == undefined) {
                list.push(instrument);
            }
        })

        setInstrumentsListTocompanySelected(list);
        setaddInstrumentModalOpen(true)
    }

    const radars = () => {
        //setInstrumentList(!instrumentList);
        dispatch(viewRadars(true));
         history.push(`/punctualRelationshipByCompany/''/''/''`);
    }

    const goInstrument = (instrument) => {
        dispatch(selectInstrument(instrument));
        dispatch(getAxes(instrument.id));
        history.push(`/punctualRelationshipAdmin`);
    }

    const logout = () => {
        dispatch(logOut(user.user.id));
        history.push('/');
    }

    const goInstrumentManagment = (id) => {
        history.push(`/instrumentManagment/${id}`);
    }

    const report = () => {
        history.push(`/report`);
    }

    const trazability = ()=>{
        history.push(`/trazability`);
    }

    const deleteInstrumentfromCompany = (instrument, company) => {
    }

    return (
        <div>
            <div style={{ position: 'fixed', top: '0', alignItems: 'start', zIndex: '10' }} className="navbar d-flex  justify-content-end">
            <div onClick={trazability} style={{ width: '250px', padding: '0xp, 10px, 0px, 10px', marginRight: '20px' }} className="logOut">
                    Trazabilidad
                </div>
                <div onClick={report} style={{ width: '250px', padding: '0xp, 10px, 0px, 10px', marginRight: '20px' }} className="logOut">
                    Generar reportes
                </div>
                <div style={{ display: 'inline-block' }}>
                    <div onClick={radars} style={{ width: '250px', padding: '0xp, 10px, 0px, 10px', marginRight: '20px' }} className="logOut">
                        ver Radares por empresa
                    </div>
                    {/* {instrumentList &&
                        <div className="radarList">
                            {
                                instruments.filter(instrum => instrum.type == 1).map(item => {
                                    return <div style={{ cursor: 'pointer' }} onClick={() => goInstrument(item)}>{item.name}</div>
                                })
                            }
                        </div>

                    } */}
                </div>

                <div onClick={logout} className="logOut">Salir</div>
            </div>
            <div className="adminDashboardmain">
                {instruments !== undefined &&
                    <div className="dashItem">
                        <h4>Instrumentos</h4>
                        <div onClick={openModalInstrument} className="createButton">Crear Instrumento</div>
                        <br />
                        <div style={{ backgroundColor: 'var(--grayTwo)', width: '99%', marginLeft: 'auto', marginRight: 'auto' }} className="row">
                            <div style={{ textAlign: 'left' }} className="col-12 col-md-12 col-sm-12"><strong>Instrumento</strong></div>
                        </div>
                        <div style={{ overflowY: 'auto', height: '320px', marginTop: '20px', overflowX: 'hidden' }}>
                            {instruments.map(item => {
                                return (
                                    <div>
                                        <div style={{ marginBottom: '10px' }} className="row">
                                            <div style={{ textAlign: 'left' }} className="col-6 col-md-6 col-sm-6">
                                                <div style={{ marginRight: '10px' }}>{item.name}</div>
                                            </div>
                                            <div className="col-6 col-md-6 col-sm-6">
                                                <div style={{ width: '200px' }} className="editButton" onClick={() => goInstrumentManagment(item.id)}>ir al instrumento</div>
                                            </div>
                                        </div>
                                    </div>);
                            })}
                        </div>
                    </div>
                }

                {companies !== undefined &&
                    <div className="companiesTable">
                        <h4>Empresas</h4>
                        <div onClick={openModalCompany} className="createButton">Registrar empresa</div>
                        <br />
                        <div className="row">
                            <div style={{ textAlign: 'left', marginLeft: '20px', backgroundColor: 'var(--grayTwo)' }} className="col-6 col-sm-5 col-md-4 col-lg-4"><strong>Empresa</strong></div>
                            <div style={{ textAlign: 'left', backgroundColor: 'var(--grayTwo)' }} className="col-6 col-sm-5 col-md-4 col-lg-4"><strong>Instrumentos Asociados</strong></div>
                            <div style={{ backgroundColor: 'var(--grayTwo)' }} className="col-6 col-sm-5 col-md-3 col-lg-3"></div>
                        </div>
                        <div style={{ overflowY: 'auto', height: '320px', marginTop: '20px', overflowX: 'hidden', padding: '20px' }}>
                            {
                                companies.map(item => {
                                    return (
                                        <div className="row">
                                            <div className="instrumentItem">
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4" style={{ marginRight: '10px', textAlign: 'left' }}>
                                                    {item.name}
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4" style={{ marginRight: '10px', textAlign: 'left' }}>
                                                    {item.instruments.map(instrument => {
                                                        return (<div>
                                                            {instrument.name}
                                                            <div onClick={() => deleteInstrumentfromCompany(instrument.id, item.id)} className="deleteButton">Remover</div>
                                                        </div>)
                                                    })}
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                                                    <div onClick={() => openAddInstrumentModalOpen(item)} style={{ float: 'right' }} className="editButton">Agregar instrumento</div>
                                                </div>
                                                {/* <div className="col-12 col-sm-12 col-md-2 col-lg-2">
                                                    <div style={{ float: 'right', marginRight: '10px', cursor: 'pointer', color: 'blue' }}>Remover instrumento</div>
                                                </div> */}
                                            </div>
                                        </div>);
                                })
                            }
                        </div>
                    </div>
                }
                {users !== undefined &&
                    <div className="usersTable">
                        <h4>Usuarios</h4>
                        <div onClick={() => openModalUser('')} className="createButton">Registrar usuario</div>
                        <br />
                        <div className="row" style={{ backgroundColor: 'var(--grayTwo)' }}>
                            <div style={{ textAlign: 'left' }} className="col-3 col-sm-2 col-md-2 col-lg-2"><strong>Nombre</strong></div>
                            <div style={{ textAlign: 'left' }} className="col-3 col-sm-2 col-md-2 col-lg-2"><strong>Apellido</strong></div>
                            <div style={{ textAlign: 'left' }} className="col-2 col-sm-2 col-md-2 col-lg-2"><strong>Cedula</strong></div>
                            <div style={{ textAlign: 'left' }} className="col-2 col-sm-2 col-md-2 col-lg-2"><strong>Empresa</strong></div>
                        </div>
                        <div style={{ overflowY: 'auto', height: '320px', marginTop: '20px', overflowX: 'hidden', paddingRight: '40px' }}>
                            {
                                users.map(item => {
                                    return (
                                        <div>
                                            <div className="row itemUser">
                                                <div style={{ textAlign: 'left' }} className="col-3 col-sm-2 col-md-2 col-lg-2">
                                                    <div style={{ marginRight: '10px' }}>{item.name}</div>
                                                </div>
                                                <div style={{ textAlign: 'left' }} className="col-3 col-sm-2 col-md-2 col-lg-2">
                                                    <div style={{ marginRight: '10px' }}>{item.last_name}</div>
                                                </div>
                                                <div style={{ textAlign: 'left' }} className="col-2 col-sm-2 col-md-2 col-lg-2">
                                                    <div style={{ marginRight: '10px' }}>{item.cedula}</div>
                                                </div>
                                                <div style={{ textAlign: 'left' }} className="col-2 col-sm-4 col-md-4 col-lg-4">
                                                    <div style={{ marginRight: '10px' }}>{item.company_name}</div>
                                                </div>
                                                <div className="col-2 col-sm-2 col-md-3 col-lg-2">
                                                    <div onClick={() => openModalUser(item)} className="editButton">Editar</div>
                                                </div>
                                            </div>
                                        </div>);
                                })
                            }
                        </div>
                    </div>

                }
            </div>
            <UserModal userToEdit={userToEdit} userModalopen={userModalopen} setUserModalopen={setUserModalopen} />
            <InstrumentModal instrumentModalopen={instrumentModalopen} setInstrumentModalopen={setInstrumentModalopen} />
            <ComapanyModal companyModalOpen={companyModalOpen} setCompanyModalOpen={setcompanyModalOpen} />
            <AddInstrumentModal
                companySelected={companySelected}
                instrumentsListTocompanySelected={instrumentsListTocompanySelected}
                addInstrumentModalOpen={addInstrumentModalOpen}
                setaddInstrumentModalOpen={setaddInstrumentModalOpen} />
        </div>
    )
}

export default AdminDashboard
