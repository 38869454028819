import React, { useState } from 'react';
import Modal from 'react-modal';
import clienteAxios from '../../../config/axios';
import './questionModal.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#root');

export const QuestionModal = ({ questionModalopen, setQuestionModalopen, instrument, getInstrumentsWithAll }) => {

    //states
    const [formValues, setformValues] = useState('')
    const [error, setError] = useState('');
    const [Radar, setRadar] = useState('')
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setQuestionModalopen(false);
        getInstrumentsWithAll();
    }

    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const sendData = async (event) => {
        event.preventDefault();
        let internalError = '';
        if (formValues.question === '' || formValues.question === undefined) {
            internalError = internalError + 'El nombre es requerido, ';
        }
        if (formValues.component_id === '' || formValues.component_id === undefined) {
            internalError = internalError + 'El Eje es requerido, ';
        }
        if (internalError === '') {
            let response = await clienteAxios.post(`createQuestion`, formValues);
            if (response.status === 200) {
                closeModal();
                alert('Pregunta creada con exito')
                setRadar('');
                setformValues('')
            }
            setError('');
        } else {
            setError(internalError);
        }
    }

    const changeRadar = (event) => {
        setRadar(instrument.find(rad => rad.id == event.target.value))
    }

    return (
        <div className="mainModal">
            <Modal
                isOpen={questionModalopen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="userModalBox">
                    <div className="userModalHeader">
                        <div>
                            <strong>Crear Pregunta</strong>
                        </div>
                        <div style={{ cursor: 'pointer', float: 'right' }} onClick={closeModal}><strong>X</strong></div>
                    </div>
                    <div className="userModalBody">
                        <form >
                            <div className="userFormItem">
                                <label>Nombre</label>
                                <input onChange={handleInputChange} type="text" value={formValues.question} name="question" />
                            </div>
                            <div className="col-12 col-sm-8 col-md-11 inputs">
                                <label>Radar</label>
                                <select name="axes_id" value={formValues.axes_id} onChange={changeRadar}>
                                    <option value="">Seleccione Radar</option>
                                    {instrument !== "" &&
                                        instrument.map(radar => {
                                            return <option value={radar.id}>{radar.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <br/>
                            <br/>
                            {Radar !== "" &&
                                <div className="col-12 col-sm-8 col-md-11 inputs">
                                    <label>Eje</label>
                                    <select name="component_id" value={formValues.component_id} onChange={handleInputChange}>
                                        <option value="">Seleccione Eje</option>
                                        {Radar.component.length > 0 &&
                                            Radar.component.map(component => {
                                                return <option value={component.id}>{component.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            }
                            {error !== '' && <div style={{ width: '300px', color: 'red' }}>{error}</div>}
                            <div onClick={sendData} className="sendButton">Crear</div>
                        </form>
                    </div>
                </div>
            </Modal >
        </div>

    )
}
