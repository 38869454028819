import {
    SET_INSTRUMENTS, SET_INSTRUMENT, SET_AXES, SET_RADARS
} from '../types/instrument';

//state
const initialState = {
    instrumentsList: [],
    instrument: [],
    axes: [],
    radarView:false
}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_INSTRUMENTS:
            return {
                ...state,
                instrumentsList: action.payload,
            }


        case SET_INSTRUMENT:
            return {
                ...state,
                instrument: action.payload,
            }

        case SET_AXES:
            return {
                ...state,
                axes: action.payload,
            }

        case SET_RADARS:
            return {
                ...state,
                radarView: action.payload,
            }

        default:
            return state;
    }
}