import {SET_USERS} from '../types/user';
import clienteAxios from '../../config/axios';


//get data
export function getUsers() {
    return async (dispatch) => {
        try {
            let response = '';
            response = await clienteAxios.get(`users`);
            dispatch(setUsers(response.data));
        } catch (error) {
        }
    }
}

const setUsers = (users) => ({
    type: SET_USERS,
    payload: users
});