import React, { useState, useEffect } from 'react';
//import './App.css';

function BBB({width, height, marginTop}) {

  const [urlMeeting, setUrlMeeting] = useState('');

  useEffect(() => {
    bigBlueButton();
  }, [])


  const bigBlueButton = () => {
    const bbb = require('bigbluebutton-js')

    // BBB_URL and BBB_SECRET can be obtained
    // by running bbb-conf --secret on your BBB server
    // refer to Getting Started for more information
    let api = bbb.api(
      // process.env.BBB_URL, 
      // process.env.BBB_SECRET
      'https://bbb.examen.club/bigbluebutton/',
      '4JeA9BqnJMl2lcDmjC5NsuYfsJRq6i5KRNlGpvOicQ'
    )
    let http = bbb.http

    // api module itslef is responsible for constructing URLs
    let meetingCreateUrl = api.administration.create('My Meeting', '1', {
      duration: 10,
      attendeePW: 'secret',
      moderatorPW: 'supersecret',
    })

    // http method should be used in order to make calls
    http(meetingCreateUrl).then((result) => {

      let moderatorUrl = api.administration.join('moderator', '1', 'supersecret')
      let attendeeUrl = api.administration.join('attendee', '1', 'secret')
      setUrlMeeting(`${moderatorUrl}`)
      let meetingEndUrl = api.administration.end('1', 'supersecret')
    
    })

  }


  return (
    <div>
      <iframe src={urlMeeting} width={width} height={height} allow="camera; microphone; display-capture; autoplay; clipboard-write; fullscreen">
      </iframe>
    </div>
  );
}

export default BBB;
