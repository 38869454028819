import { AUTHENTICATED, USER } from '../types/auth';
import cookie from 'react-cookies'
import axios from 'axios';
import axiosLogin from '../../config/axiosLogin';
import clienteAxios from '../../config/axios';


//autenticacion
export function loginAction(user) {
    return async (dispatch) => {
        try {

            let data = new FormData();
            data.append('email', user.email);
            data.append('password', user.password);

            const response = await clienteAxios.post('login', data);

            if (response.data) {
                const maxAge = 3600 * 24 * 15;
                const expires = new Date()
                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                cookie.save('token', response.data.message, { path: "/", expires, maxAge });
                clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                dispatch(loginSuccess(true));
                dispatch(setUser(response.data));

            } else if (response.data.message) {
                return 'invalid';
            }


        } catch (error) {
            alert('usuario o contraseña no validos')
            return 'error';
        }
    }
}


export function validateTokenAuth(token) {
    return async (dispatch) => {
        try {

            let response = await clienteAxios.get(`validateToken/${token}`);

            if (Date.parse(response.data.token[0].expiration) > Date.now()) {
                const maxAge = 3600 * 24 * 15;
                const expires = new Date()
                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                cookie.save('token', response.data.token[0].token, { path: "/", expires, maxAge });
                clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token[0];
                dispatch(loginSuccess(true));
                dispatch(setUser({
                    "message": response.data.token[0],
                    "user": response.data.user[0],
                    "role": response.data.role
                }));
            } else {
                alert('autenticación incorrecta')
            }

        } catch (error) {
            return 'error';
        }
    }
}


export function logOut(id) {
    return async (dispatch) => {
        try {
            dispatch(setUser({}));
            let response = await clienteAxios.delete(`deleteToken/${id}`);
            dispatch(loginSuccess(false));
            cookie.remove('token', { path: '/' })
        } catch (error) {

        }
    }
}

//si esta logueado manda una señal al store
export const loginSuccess = authFlag => ({
    type: AUTHENTICATED,
    payload: authFlag
});


export const setUser = user => ({
    type: USER,
    payload: user
});